import useElementSize from '@/hooks/useElementSize'
import { PREVIOUS_PATHNAME_KEY } from '@/pages/legacy/consts/consts'
import { AuthUtils } from '@/utils/AuthUtils'
import { LEGACY_PATH } from '@/utils/consts'
import { PATHS } from '@/utils/routes/paths'
import { FC, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface Message extends MessageEvent {
  data: VisitLogin
}
interface VisitLogin {
  event: 'VISIT_LOGIN_PAGE'
}

const InlineFramePage: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [ref, { width, height }] = useElementSize()
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (sessionStorage.getItem(PREVIOUS_PATHNAME_KEY) === pathname) {
      setTimeout(() => {
        iframeRef.current?.contentWindow?.location.reload()
      }, 800)
    }

    sessionStorage.setItem(PREVIOUS_PATHNAME_KEY, pathname)
  }, [pathname])

  useEffect(() => {
    const handleMessage = (message: Message) => {
      switch (message.data.event) {
        case 'VISIT_LOGIN_PAGE':
          AuthUtils.logout()
          navigate(PATHS['auth.login'])
          break
        default:
          return null
      }
    }
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [navigate])

  return (
    <Wrapper>
      <IframeWrap ref={ref}>
        <iframe
          ref={iframeRef}
          title={pathname}
          // src={`http://localhost:3000${LEGACY_PATH}${pathname}?layout=iframe`}
          src={`${window.location.origin}${LEGACY_PATH}${pathname}?layout=iframe`}
          width={`${width}px`}
          height={`${height}px`}
        />
      </IframeWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0;
  height: calc(100% + 48px);
  margin: -24px;
  overflow: hidden;
`
const IframeWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  iframe {
    border: 0;
  }
`
export default InlineFramePage
