import { useCallback } from 'react'
import PageLayout from '@/components/system/PageLayout'
import { useParsedQuery } from '@/hooks/useParsedQuery'
import { InterfaceHistoryParams } from '@/services/history/history.types'
import { FormProvider, useForm } from 'react-hook-form'
import { useGetInterfaceHistory } from '@/services/history/useHistoryQuries'
import { defaultValues, InterfaceHistoryForm } from '@/pages/history/interfaceHistory/components/InterfaceHistoryForm'
import { InterfaceHistoryTable } from '@/pages/history/interfaceHistory/components/InterfaceHistoryTable'
import { MenuStorage } from '@/utils/storage/MenuStorage'
import { useMenuOnSession } from '@/components/layout/MainMenuOnSessionProvider'
import { flushSync } from 'react-dom'
import { differenceInMonths, parse } from 'date-fns'
import MAlert from '@/utils/MAlert'

const InterfaceHistory = () => {
  const { reload } = useMenuOnSession()
  const { parsedQs, replaceQuery } = useParsedQuery<InterfaceHistoryParams>(defaultValues)
  const form = useForm<InterfaceHistoryParams>({
    defaultValues: parsedQs,
  })
  const values = form.watch()
  const { data, isFetching, refetch } = useGetInterfaceHistory(values)

  const onSearch = useCallback(() => {
    const [startDate, endDate] = values.plnDate
    const monthsDifference = differenceInMonths(
      parse(endDate as string, 'yyyyMMdd', new Date()),
      parse(startDate as string, 'yyyyMMdd', new Date()),
    )

    if (monthsDifference > 2) {
      MAlert.show('실행일자는 3개월 이내만 가능합니다.')
      return
    }

    MenuStorage.menuOnSession.setSearch(values)
    flushSync(() => replaceQuery(values))
    reload()
    refetch()
  }, [values])

  return (
    <PageLayout>
      <FormProvider {...form}>
        <InterfaceHistoryForm onSearch={onSearch} />
        <InterfaceHistoryTable data={data?.list ?? []} isLoading={isFetching} />
      </FormProvider>
    </PageLayout>
  )
}

export default InterfaceHistory
