import request from '@/utils/request'
import {
  AddFailCountReq,
  AddFailCountRes,
  KeepDepartmentUsersReq,
  LoginReq,
  LoginRes,
  LoginUserInfo,
  OtpSecretReq,
  OtpSecretRes,
  SignUpReq,
  SignUpRes,
  UpdateDepartmentUsersReq,
  UpdateDepartmentUsersRes,
  UpdateUserPwdReq,
} from '@/services/auth/login.types'
import { MenuListReq, MenuListRes } from '@/services/auth/menu.types'

export const callToCheckLoginUser = () => request.get<LoginUserInfo>(`/api/auth/check`)
export const callToLogin = (params: LoginReq) => request.post<LoginRes, LoginReq>(`/api/auth/login`, params, { noAuth: true })
export const callToSignUp = (params: SignUpReq) => request.post<SignUpRes, SignUpReq>(`/api/auth/register`, params, { noAuth: true })

export const callToResetOtp = (params: LoginReq) => request.post<LoginRes, LoginReq>(`/api/auth/resetotp`, params, { noAuth: true }) // reset 하면서 바로 로그인 처리
export const callToGetOtpSecretKey = (params: OtpSecretReq) =>
  request.post<OtpSecretRes, { data: string }>(`/api/auth/otp`, { data: JSON.stringify(params) })

export const callToAddFailCount = (email: string) => request.post<AddFailCountRes, AddFailCountReq>(`/api/auth/chkotp`, { data: email })

export const callToUpdateUserPassword = (params: UpdateUserPwdReq) =>
  request.post<boolean, { data: string }>(`/api/auth/modify`, { data: JSON.stringify(params) })

export const callToGetMenuList = (params: MenuListReq) =>
  request.post<MenuListRes, { params: string }>(`/api/auth/menu`, { params: JSON.stringify(params) })

export const callKeepDepartmentUsers = (params: KeepDepartmentUsersReq) =>
  request.post<UpdateDepartmentUsersRes, { data: string }>(`/api/grid/save`, { data: JSON.stringify(params) })

export const callSaveDepartmentUsers = (params: UpdateDepartmentUsersReq) =>
  request.post<boolean, { data: string }>(`/api/grid/save`, { data: JSON.stringify(params) })
