import request from '@/utils/request'
import {
  AddDeliveryAreaReq,
  CitiesOnDeliveryAreasRes,
  DeliveryAreaItem,
  GetDeliveryAreasReq,
  UpdateDeliveryAreaReq,
} from '@/services/deliveryAreas/types'

export const getDeliveryAreas = (params: ApiPaginationReq<GetDeliveryAreasReq>) =>
  request.get<ApiResponseV2<ApiPagination<DeliveryAreaItem[]>>, ApiPaginationReq<GetDeliveryAreasReq>>('/api/v1/delivery-areas', params)

export const addDeliveryArea = (params: AddDeliveryAreaReq) => request.post<ApiResponseV2, AddDeliveryAreaReq>('/api/v1/delivery-areas', params)
export const updateDeliveryArea = (params: UpdateDeliveryAreaReq[]) =>
  request.post<ApiResponseV2, UpdateDeliveryAreaReq[]>(`/api/v1/delivery-areas/bulk`, params)

export const getCitiesOnDeliveryAreas = () => request.get<ApiResponseV2<CitiesOnDeliveryAreasRes>>('/api/v1/delivery-areas/cities')
