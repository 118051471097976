import * as React from 'react'
import Input, { InputProps } from '@/components/content/Input'
import styled from 'styled-components'
import { colorSystem } from '@/styles/theme'
import RemixIcon from '@/components/content/RemixIcon'

interface Props extends InputProps {
  onClick?: () => void
}

function SearchInput({ onClick, ...inputProps }: Props) {
  return (
    <SearchInputWrap>
      <Input {...inputProps} block />
      <SearchInputButton type="button" onClick={onClick}>
        <RemixIcon name="ri-search-line" size={20} color={colorSystem.icon.secondary} />
      </SearchInputButton>
    </SearchInputWrap>
  )
}

const SearchInputWrap = styled.div`
  position: relative;
`

const SearchInputButton = styled.button`
  position: absolute;
  right: 8px;
  top: 3px;
`

export default SearchInput
