import React, { CSSProperties, useMemo } from 'react'
import cx from 'classnames'

interface RemixIconProps {
  size?: number
  name?: string
  disabled?: boolean
  color?: string
  role?: string
  style?: CSSProperties
  onClick?: React.MouseEventHandler
}

function RemixIcon({ className, size = 18, name, disabled, color, role, style, onClick }: PropsWithClass<RemixIconProps>) {
  const iconStyle = useMemo(
    () => ({
      fontSize: size ? `${size}px` : undefined,
      color,
      ...style,
    }),
    [size, style],
  )

  return !name ? null : (
    <i className={cx(className, name)} data-disabled={disabled} role={onClick ? 'button' : role} style={iconStyle} onClick={onClick} />
  )
}

export default RemixIcon
