import React, { PropsWithChildren } from 'react'
import styled, { css, CSSObject } from 'styled-components'
import { buttonSize, buttonSystem } from '@/styles/theme'

export type ButtonKind = keyof typeof buttonSystem
export interface ButtonProps {
  id?: string
  className?: string
  fit?: boolean
  type?: string
  kind: ButtonKind
  size?: 'sm' | 'md' | 'lg'
  disabled?: boolean
  block?: boolean
  content?: React.ReactNode
  style?: CSSObject
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

function Button(props: PropsWithChildren<ButtonProps>) {
  const { id, className, type = 'button', kind, size = 'md', fit, disabled, block, children, content, style, onClick, ...rest } = props

  return (
    <ButtonWrap
      id={id}
      type={type}
      className={className}
      kind={kind}
      size={size}
      disabled={disabled}
      $block={block}
      $fit={fit}
      style={style}
      onClick={onClick}
      {...rest}
    >
      {content ?? children}
    </ButtonWrap>
  )
}

const ButtonWrap = styled.button<Omit<ButtonProps, 'disabled' | 'block' | 'fit'> & { $block?: boolean; $fit?: boolean }>`
  display: inline-flex;
  justify-content: center;
  border-radius: 6px;
  outline: none;
  box-shadow: none;
  white-space: nowrap;
  gap: 4px;
  align-items: center;

  ${({ $fit }) =>
    !$fit &&
    css`
      min-width: 120px;
    `}

  ${({ $block }) =>
    $block &&
    css`
      display: flex;
      width: 100% !important;
      min-width: initial !important;
    `}

  ${({ kind }) => css`
    ${buttonSystem[kind]}
  `}

    ${({ size }) =>
    size &&
    css`
      ${buttonSize[size]}
    `}
`

export default Button
