import { MenuOnSession } from '@/services/auth/menu.types'
import { Utils } from '@/utils/Utils'
import { isArray } from 'lodash-es'

export class MenuOnSessionWrap {
  name = ''

  constructor(name: string) {
    this.name = name
  }

  getCurrentMenu = () => {
    const menus = this.get()
    const curCode = window.location.pathname.match(/\/([a-zA-Z\d]*)$/i)?.[1] ?? ''
    return menus.find((item) => item.code === curCode)
  }

  get = () => {
    let list: MenuOnSession[] = []

    try {
      const curMenuOnSession = sessionStorage.getItem(this.name) || ''
      if (curMenuOnSession) list = JSON.parse(curMenuOnSession) || []
    } catch (e) {
      // ERROR
      // console.dir(e)
    }

    return isArray(list) ? list : []
  }

  getMenuWithCode = (code: string) => {
    const menus = this.get()
    return menus.find((item) => item.code === code)
  }

  add = ({ code, name, link }: MenuOnSession) => {
    const list = this.get()
    if (!list.find((menu) => menu.code === code)) {
      list.push({ code, name, link })
      sessionStorage.setItem(this.name, JSON.stringify(list))
    }
  }

  setSearch = (query: Object) => {
    const list = this.get()
    const currentMenu = this.getCurrentMenu()
    const target = list.find((item) => item.code === currentMenu?.code)
    if (target) {
      target.search = Utils.qsStringify(query ?? '')
      sessionStorage.setItem(this.name, JSON.stringify(list))
    }
  }

  remove = (code: string) => {
    const list = this.get()
    const idxToRemove = list.findIndex((menu) => menu.code === code)
    const menuToRemove = list.splice(idxToRemove, 1)?.[0]

    sessionStorage.setItem(this.name, JSON.stringify(list))

    return [menuToRemove, idxToRemove] as const
  }

  clear = () => {
    sessionStorage.removeItem(this.name)
  }
}
