import { DepartmentSearchReq, DepartmentSearchRes, ShipperSearchReq, ShipperSearchRes } from '@/services/shipper/types'
import request from '@/utils/request'

export const getShipperList = (shipperName = '', page = 1) =>
  request.post<ShipperSearchRes, { params: string }>(
    `/api/common/auth/search`,
    {
      params: JSON.stringify({
        MENU_CD: 'SPR_NM',
        USR_ID: 'TEMP',
        params: { shipper_name: shipperName },
        type: 'keyboard',
        page,
      } as ShipperSearchReq),
    },
    {
      noAuth: true,
    },
  )

export const getDepartmentList = (shipperCode = '', page = 1) =>
  request.post<DepartmentSearchRes, { params: string }>(
    `/api/common/auth/search`,
    {
      params: JSON.stringify({
        MENU_CD: 'DEPT_NM',
        USR_ID: 'TEMP',
        params: { SPR_NM: shipperCode },
        type: 'click',
        page,
      } as DepartmentSearchReq),
    },
    {
      noAuth: true,
    },
  )
