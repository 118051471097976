import { QueryCache, QueryClient } from '@tanstack/react-query'
import MAlert from '@/utils/MAlert'
import { Utils } from '@/utils/Utils'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (e, query) => {
      if (query.meta?.noErrorMessage) {
        return
      }
      MAlert.show(Utils.getApiErrMessage(e, '데이터를 가져올 수 없습니다. 다시 시도해 주세요.'))
    },
  }),
})
