import request from '@/utils/request'
import {
  HistoryDownloadLogReq,
  HistoryDownloadLogRes,
  HistoryUserLogReq,
  HistoryUserLogRes,
  InterfaceHistoryReq,
  InterfaceHistoryRes,
} from '@/services/history/history.types'
import { convertKeysToGridRequest, convertKeysToGridResponse } from '@/utils/service/serviceFilter'

export const getHistoryUserLog = (params: HistoryUserLogReq) =>
  request
    .post<HistoryUserLogRes, GridApiRequestBody>('/api/grid/search', convertKeysToGridRequest(params, ['email']))
    .then(convertKeysToGridResponse)

export const getHistoryDownloadLog = (params: HistoryDownloadLogReq) =>
  request
    .post<HistoryDownloadLogRes, GridApiRequestBody>('/api/grid/search', convertKeysToGridRequest(params, ['email']))
    .then(convertKeysToGridResponse)

export const getInterfaceHistory = (params: InterfaceHistoryReq) =>
  request.post<InterfaceHistoryRes, GridApiRequestBody>('/api/grid/search', convertKeysToGridRequest(params)).then(convertKeysToGridResponse)
