import styled from 'styled-components'
import { VStack } from '@/components/content/Stack'
import Text from '@/components/content/Text'

function NotFoundLayout() {
  return (
    <LayoutWrapper>
      <VStack align="center" justify="center">
        <img
          src="https://cdni.iconscout.com/illustration/premium/thumb/404-7304110-5974976.png?f=webp"
          alt="404"
          style={{ pointerEvents: 'none' }}
        />
        <Text kind="Headline_24_bold" color="black" style={{ position: 'relative', top: '-100px' }}>
          NOT FOUND
        </Text>
      </VStack>
    </LayoutWrapper>
  )
}

const LayoutWrapper = styled(VStack)`
  padding: 62px 0;
  width: 100%;
  height: 100%;
  background-color: white;
  justify-content: center;
`

export default NotFoundLayout
