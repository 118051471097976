import React from 'react'
import styled from 'styled-components'

function AuthContent({ children }: React.PropsWithChildren) {
  return <AuthContentWrap>{children}</AuthContentWrap>
}

const AuthContentWrap = styled.div`
  display: inline-block;
  min-width: 470px;
  margin: 0 auto;
  padding: 40px 30px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.8);

  position: relative;
  left: 50%;
  transform: translateX(-50%);
`

export default AuthContent
