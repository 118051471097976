import { ToastList, ToastProps } from '@/components/content/Toast'
import { createRoot, Root } from 'react-dom/client'
import { Utils } from '@/utils/Utils'

const TOAST_ID = 'moms-toast'

class MToastAction {
  list: ToastProps[] = []

  root: Root | null = null

  constructor() {
    const $wrapper = document.getElementById(TOAST_ID)
    if (!$wrapper) return

    this.root = createRoot($wrapper)
  }

  destory = (id: string) => {
    const deletedIdx = this.list.findIndex((toast) => toast.id === id)
    this.list.splice(deletedIdx, 1)

    if (this.root) {
      this.root.render(<ToastList list={this.list} />)
    }
  }

  show = (message: string, props?: Pick<ToastProps, 'kind'>) => {
    this.list.push({ id: Utils.uuid(), message, ...props })

    if (this.root) {
      this.root.render(<ToastList list={this.list} />)
    }
  }

  error = (message: string) => {
    this.list.push({ id: Utils.uuid(), message, kind: 'error' })

    if (this.root) {
      this.root.render(<ToastList list={this.list} />)
    }
  }
}

const MToast = new MToastAction()
export default MToast
