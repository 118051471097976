import styled from 'styled-components'
import { borderRadius, colorSystem, zIndexSystem } from '@/styles/theme'

export const DateRangePickerWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .react-datepicker {
    min-width: 250px;
    border: 1px solid ${colorSystem.border.element};
    ${borderRadius.sm}
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: ${zIndexSystem.modal};
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__header {
    background: ${colorSystem.bg.common.secondary_hover};
    border-bottom: 1px solid ${colorSystem.border.element};
  }

  .react-datepicker__header__dropdown {
    padding: 8px 0;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border: 1px solid ${colorSystem.border.element};
    padding: 2px 4px;
    ${borderRadius.sm}
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__week,
  .react-datepicker__day-names {
    display: flex;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    flex: 1;
  }

  .react-datepicker__triangle {
    fill: ${colorSystem.bg.common.secondary_hover} !important;
    color: ${colorSystem.bg.common.secondary_hover} !important;
    stroke: ${colorSystem.border.element} !important;
  }
`
