import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import MainFooter from '@/components/layout/MainFooter'
import MainHeader from '@/components/layout/MainHeader'
import MainSideBar from '@/components/layout/MainSideBar'
import MainMenuOnSession from '@/components/layout/MainMenuOnSession'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@/utils/consts'
import { Utils } from '@/utils/Utils'
import { MenuOnSessionProvider } from '@/components/layout/MainMenuOnSessionProvider'

function MainLayout() {
  return (
    <MenuOnSessionProvider>
      <LayoutWrapper>
        <MainHeader />
        <LayoutMid>
          <MainSideBar />
          <LayoutContentWrap>
            <MainMenuOnSession />
            <LayoutContent>
              <Outlet />
            </LayoutContent>
          </LayoutContentWrap>
        </LayoutMid>
        <MainFooter />
      </LayoutWrapper>
    </MenuOnSessionProvider>
  )
}

const LayoutWrapper = styled.div`
  background-image: url('${Utils.assetUrl('/login_bg_1.jpg')}');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`

const LayoutMid = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
`

const LayoutContentWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
`

const LayoutContent = styled.div`
  height: 100%;
  padding: 24px;
  overflow-y: auto;
  background: #fff;
`

export default MainLayout
