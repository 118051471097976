import { DEV_API_URL } from '@/utils/consts'

export type ApiType = keyof typeof DEV_API_URL

export class ApiStorage {
  static apiType = {
    name: 'apiType',
    get() {
      return (sessionStorage.getItem(this.name) ||
        (() => {
          const hostname = window.location.hostname

          if (hostname.startsWith('dev-2.moms')) return 'DEV2'
          if (hostname.startsWith('dev-3.moms')) return 'DEV3'
          return 'DEV'
        })()) as ApiType
    },
    set(type: ApiType) {
      sessionStorage.setItem(this.name, type)
    },
    clear() {
      sessionStorage.removeItem(this.name)
    },
  }
}
