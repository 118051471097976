import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import Button, { ButtonProps } from '@/components/content/Button'
import styled, { css } from 'styled-components'
import { HStack } from '@/components/content/Stack'
import { colors, colorSystem, MODAL_OPENED_CN, zIndexSystem } from '@/styles/theme'
import RemixIcon from '@/components/content/RemixIcon'

const MODAL_ANIMATION_MS = 120

// sm/md/lg = PC | full-screen = MOBILE
type ModalSize = 'sm' | 'md' | 'lg' | 'full-screen'

export interface ModalProps {
  open: boolean
  closable?: boolean
  type?: 'alert' | 'confirm'
  size?: ModalSize
  maxHeight?: number
  // contents
  title?: React.ReactNode
  content?: React.ReactNode
  children?: React.ReactNode | ((contentRef: React.RefObject<HTMLDivElement>) => React.ReactNode)
  // className
  className?: string
  bodyClassName?: string
  headerClassName?: string
  contentClassName?: string
  footerClassName?: string
  // buttons
  buttons?: ButtonProps[]
  closeText?: string
  confirmText?: string
  closeIcon?: boolean
  // actions
  onClose?: () => void
  onConfirm?: () => void
  fixedTopContent?: React.ReactNode
  fixedBottomContent?: React.ReactNode
}

const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const {
    open,
    size = 'sm',
    maxHeight,
    closable,
    className,
    bodyClassName,
    headerClassName,
    contentClassName,
    footerClassName,
    title = '',
    closeIcon,
    type,
    buttons,
    content,
    children,
    closeText,
    confirmText,
    onClose,
    onConfirm,
    fixedTopContent,
    fixedBottomContent,
  } = props
  const [realOpen, setRealOpen] = useState(false)
  const [fadeOpen, setFadeOpen] = useState(false) // For animation
  const hasFooter = useMemo(() => !!(type || buttons?.length), [type, buttons])
  const contentRef = useRef<HTMLDivElement>(null)
  const childrenWithRef = typeof children === 'function' ? children(contentRef) : children

  useEffect(
    function onOpenWithAnimation() {
      if (open) {
        setRealOpen(true)
        document.body.classList.add(MODAL_OPENED_CN)

        setTimeout(() => {
          setFadeOpen(true)
        }, MODAL_ANIMATION_MS)
      } else {
        setFadeOpen(false)
        setTimeout(() => setRealOpen(false), MODAL_ANIMATION_MS + 50)
        document.body.classList.remove(MODAL_OPENED_CN)
      }

      return () => {
        document.body.classList.remove(MODAL_OPENED_CN)
      }
    },
    [open],
  )

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        onClose?.()
      }
    }

    if (open) {
      window.addEventListener('keydown', handleEscKey)
    }

    return () => {
      window.removeEventListener('keydown', handleEscKey)
    }
  }, [open])

  return !realOpen ? null : (
    <ModalDialog ref={ref} className={className}>
      <ModalDimmed onClick={closable ? onClose : undefined} />
      <ModalBody className={bodyClassName} open={fadeOpen} size={size} type={type} maxHeight={maxHeight}>
        {(title || size === 'full-screen') && (
          <ModalHeader className={headerClassName}>
            <span>{title}</span>
            {closeIcon && <RemixIcon name="ri-close-line" onClick={onClose} />}
          </ModalHeader>
        )}
        {fixedTopContent}
        <ModalContent
          className={contentClassName}
          $title={!!title}
          $type={type}
          ref={contentRef}
          $hasFooter={hasFooter}
          $hasTopContent={!!fixedTopContent}
        >
          {content ?? childrenWithRef}
        </ModalContent>
        {fixedBottomContent}

        {hasFooter && (
          <ModalFooter className={footerClassName} gap={8} $type={type} justify="center">
            {type === 'alert' ? (
              <Button kind="primary_light" onClick={onClose} block>
                {closeText ?? '확인'}
              </Button>
            ) : type === 'confirm' ? (
              <>
                <Button kind="secondary" onClick={onClose}>
                  {closeText ?? '취소'}
                </Button>
                <Button kind="primary_light" onClick={onConfirm}>
                  {confirmText ?? '확인'}
                </Button>
              </>
            ) : (
              buttons?.map((btn) => <Button {...btn} fit size="md" key={`mobile-layout-btn-${btn.content}`} />)
            )}
          </ModalFooter>
        )}
      </ModalBody>
    </ModalDialog>
  )
})

const ModalDialog = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndexSystem.modal};
`

const ModalDimmed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
`

const ModalBody = styled.div<Pick<ModalProps, 'size' | 'open' | 'type' | 'maxHeight'>>`
  background: ${colors.white};
  max-width: calc(100vw - 20px);
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'calc(100% - 80px)')};
  position: relative;
  z-index: ${zIndexSystem.modal};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: opacity ${MODAL_ANIMATION_MS / 1000}s linear;

  display: flex;
  flex-direction: column;

  border-radius: 16px;
  width: ${({ size }) => {
    if (size === 'sm') return '480px'
    if (size === 'md') return '720px'
    if (size === 'lg') return '1080px'
  }};
`

const ModalHeader = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  color: black;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  padding: 16px 32px;
`

const ModalContent = styled.div<{ $title: boolean; $type?: ModalProps['type']; $hasFooter?: boolean; $hasTopContent?: boolean }>`
  font-size: 14px;
  line-height: 26px;
  overflow-y: auto;

  ${({ $type }) =>
    !$type
      ? css`
          padding: 24px 32px;
        `
      : css`
          padding: 32px 32px 24px 32px;
        `}

  ${({ $title }) =>
    $title &&
    css`
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    `}
`

const ModalFooter = styled(HStack)<{ $type?: ModalProps['type'] }>`
  background: #fff;

  ${({ $type }) =>
    !$type &&
    css`
      border-top: 1px solid #d9d9d9;
    `}

  padding: ${({ $type }) => ($type ? '0 32px 32px 32px' : '20px 32px 24px 32px')};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

export default Modal
