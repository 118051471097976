import qs, { ParsedQs } from 'qs'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { Utils } from '@/utils/Utils'

export const useParsedQuery = <T = ParsedQs>(defaultValues?: T) => {
  const location = useLocation()
  const navigate = useNavigate()

  const parsedQs = useMemo(() => {
    const parseData = (location.search ? qs.parse(location.search.slice(1), { comma: true, parseArrays: true }) : {}) as T
    if (defaultValues) {
      Object.keys(defaultValues).forEach((_key) => {
        const key = _key as keyof T
        parseData[key] = parseData?.[key] ?? defaultValues?.[key]
      })
    }
    return parseData
  }, [location.search])

  const replaceQuery = useCallback(
    (query: object) => {
      navigate(
        {
          pathname: location.pathname,
          search: Utils.qsStringify(query),
        },
        { replace: true },
      )
    },
    [location.pathname],
  )

  const pushQuery = useCallback(
    (query: object) => {
      navigate({
        pathname: location.pathname,
        search: Utils.qsStringify(query),
      })
    },
    [location.pathname],
  )

  return { parsedQs, replaceQuery, pushQuery }
}
