import { RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

interface Size {
  width: number
  height: number
}
const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [RefObject<T> | null, Size] => {
  const elementRef = useRef<T>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  const updateSize = useCallback(() => {
    setSize({
      width: elementRef?.current?.clientWidth || 0,
      height: elementRef?.current?.clientHeight || 0,
    })
  }, [elementRef])

  useEffect(() => {
    if (elementRef) {
      updateSize()
    }
  }, [elementRef, updateSize])

  useLayoutEffect(() => {
    if (elementRef) {
      window.addEventListener('resize', updateSize)
      return () => window.removeEventListener('resize', updateSize)
    }
  }, [elementRef, updateSize])

  return [elementRef, size]
}

export default useElementSize
