import * as React from 'react'
import styled, { css } from 'styled-components'
import { ChangeEvent, useCallback } from 'react'
import Text from '@/components/content/Text'
import { colors, colorSystem } from '@/styles/theme'

interface Props<T> {
  value: T
  checked: boolean
  disabled?: boolean
  onChange: (value: T) => void
  label?: string
  className?: string
}

function Radio<T extends string>({ value, checked, disabled = false, onChange, label, className }: Props<T>) {
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value as T), [onChange])

  return (
    <RadioWrap className={className} $checked={checked} $disabled={disabled}>
      <RadioInput type="radio" id={value} value={value} checked={checked} disabled={disabled} onChange={handleChange} />
      {label && <RadioLabel kind={checked ? 'Body_14_bold' : 'Body_14_regular'}>{label}</RadioLabel>}
    </RadioWrap>
  )
}

const RadioWrap = styled.label<{ $checked: boolean; $disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid
      ${({ $checked, $disabled }) => ($disabled ? colorSystem.bg.input.disabled : $checked ? colorSystem.bg.common.highlight : colors.gray600)};
  }

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 6px;
    ${({ $disabled, $checked }) =>
      $disabled && $checked
        ? css`
            background-color: ${colorSystem.bg.input.disabled};
          `
        : $checked
          ? css`
              background-color: ${colorSystem.bg.common.highlight};
            `
          : undefined}
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: unset;
    `}
`

const RadioInput = styled.input`
  height: 0;
`

const RadioLabel = styled(Text)``

export default Radio
