import GridTable from '@/components/table/GridTable'
import { InterfaceHistoryItem } from '@/services/history/history.types'
import { PAGE_SIZE_ON_HISTORY } from '@/services/history/useHistoryQuries'
import { COLUMN_WIDTH } from '@/utils/grid/grid'
import { ColDef } from 'ag-grid-community'

interface InterfaceHistoryTableProps {
  data: InterfaceHistoryItem[]
  isLoading?: boolean
}
export const InterfaceHistoryTable = ({ data, isLoading }: InterfaceHistoryTableProps) => {
  const columnDefs: ColDef<InterfaceHistoryItem>[] = [
    { field: 'sprNm', headerName: '화주코드', width: COLUMN_WIDTH.tinyText },
    { field: 'apiNo', headerName: 'API 번호', width: COLUMN_WIDTH.tinyText },
    { field: 'apiDir', headerName: '통신방향', width: COLUMN_WIDTH.status },
    { field: 'apiTypeNm', headerName: 'API 유형', width: COLUMN_WIDTH.status },
    { field: 'plnDate', headerName: '실행일자', width: COLUMN_WIDTH.date, cellDataType: 'date' },
    { field: 'apiStatus', headerName: 'API 상태', width: COLUMN_WIDTH.status },
    { field: 'initTime', headerName: '시작시간', width: COLUMN_WIDTH.time, cellDataType: 'time' },
    { field: 'procTime', headerName: '처리시간(내부)', width: COLUMN_WIDTH.time, cellDataType: 'time' },
    { field: 'workTime', headerName: '처리시간(통신)', width: COLUMN_WIDTH.time, cellDataType: 'time' },
    { field: 'endTime', headerName: '완료시간', width: COLUMN_WIDTH.time, cellDataType: 'time' },
    { field: 'ttlCnt', headerName: '통신횟수', width: COLUMN_WIDTH.number },
    { field: 'sucCnt', headerName: '성공횟수', width: COLUMN_WIDTH.number },
    { field: 'failCnt', headerName: '실패횟수', width: COLUMN_WIDTH.number },
    { field: 'remark', headerName: 'REMARK', width: COLUMN_WIDTH.text },
    { field: 'insDate', headerName: '생성일자', width: COLUMN_WIDTH.dateTime, cellDataType: 'datetime' },
    { field: 'insBy', headerName: '생성자', width: COLUMN_WIDTH.author },
    { field: 'updDate', headerName: '수정일자', width: COLUMN_WIDTH.dateTime, cellDataType: 'datetime' },
    { field: 'updBy', headerName: '수정자', width: COLUMN_WIDTH.author },
    { field: 'filePath', headerName: '파일경로', width: COLUMN_WIDTH.text },
  ]

  return (
    <GridTable<InterfaceHistoryItem>
      rowData={data}
      isLoading={isLoading}
      pagination
      countPerPage={PAGE_SIZE_ON_HISTORY}
      paginationPageSize={PAGE_SIZE_ON_HISTORY}
      columnDefs={columnDefs}
      totalCount={data.length}
      useColumnNo
      useColumnFilter
    />
  )
}
