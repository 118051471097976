import { LoginRes, LoginUserInfo, UserInfoOnSession } from '@/services/auth/login.types'

export class AuthStorage {
  static auth = {
    name: 'authToken',
    get() {
      return sessionStorage.getItem(this.name) || ''
    },
    set(token: string) {
      sessionStorage.setItem(this.name, token || '')
    },
    clear() {
      sessionStorage.removeItem(this.name)
    },
  }

  static otpUnauthenticated = {
    name: 'otpUnauthenticated',
    get() {
      return sessionStorage.getItem(this.name) === 'true'
    },
    set(authorized: boolean) {
      return sessionStorage.setItem(this.name, JSON.stringify(authorized))
    },
    clear() {
      sessionStorage.removeItem(this.name)
    },
  }

  static otpInitImgUrl = {
    name: 'otpInitImgUrl',
    get() {
      return sessionStorage.getItem(this.name) || ''
    },
    set(imgUrl: string) {
      return sessionStorage.setItem(this.name, imgUrl)
    },
    clear() {
      sessionStorage.removeItem(this.name)
    },
  }

  static user = {
    name: 'userInfo',
    get() {
      let userInfo: Partial<UserInfoOnSession> = {}

      try {
        const curUserInfo = sessionStorage.getItem(this.name) || ''
        if (curUserInfo) {
          const info = JSON.parse(curUserInfo)
          userInfo = {
            ...info,
            shipperName: info.shipper_name,
            shipperCode: info.shipper_code,
          }
        }
      } catch (e) {
        // ERROR
        // console.dir(e)
      }

      return userInfo
    },
    set(userInfo: Partial<UserInfoOnSession>) {
      sessionStorage.setItem(this.name, JSON.stringify(userInfo))
    },
    setByLogin({ token, modifyFlag, deptMng, deptFlag, clientList, deptInfo, user }: Partial<LoginRes>) {
      if (user) {
        sessionStorage.setItem(
          this.name,
          JSON.stringify({
            ...user,
            modifyFlag,
            deptMng,
            deptFlag,
            deptInfo,
            clientList,
            // FIXME 1.0 용 (나중에 제거)
            id: user.email,
            username: user.nickname,
            token,
            auth: user.AUTH_CD,
            company: user.SPR_NM,
            deptname: user.DEPT_NM,
            orgComNm: user.ORG_SPR_NM,
            isConvertible: user.is_convertible,
          }),
        )
      }
    },
    setByAuthChecker(user: LoginUserInfo) {
      if (user) {
        sessionStorage.setItem(
          this.name,
          JSON.stringify({
            ...AuthStorage.user.get(),
            ...user,
          }),
        )
      }
    },
    clearDeptMng() {
      AuthStorage.user.set({
        ...AuthStorage.user.get(),
        deptMng: false,
        deptInfo: [],
      })
    },
    clear() {
      sessionStorage.removeItem(this.name)
    },
  }
}
