import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VStack } from '@/components/content/Stack'
import { MODAL_OPENED_CN } from '@/styles/theme'
import Modal, { ModalProps } from '@/components/content/Modal'

export interface AlertModalProps extends Omit<ModalProps, 'onClose' | 'children'> {
  id: string
  content: React.ReactNode
  onClose?: () => void
  onConfirm?: () => void
  children?: React.ReactNode
}

function AlertModal(props: AlertModalProps) {
  const { id, closable, open: initOpen, type, children, content, onClose, onConfirm, confirmText, closeText, buttons } = props
  const $prevActive = useRef<HTMLElement | null>(null)

  const [open, setOpen] = useState(initOpen)
  const alertContent = useMemo(() => {
    if (content && typeof content === 'string') {
      return content.replace(/\n/g, '<br/>')
    }

    return content ?? children
  }, [content, children])

  const handleConfirm = useCallback(() => {
    setOpen(false)
    if (onConfirm) onConfirm()
  }, [onConfirm, id])

  const handleClose = useCallback(() => {
    setOpen(false)
    if (onClose) onClose()
  }, [onClose, id])

  useEffect(
    function onCloseByEscape() {
      if (!closable) return

      const onKeyupEscape = (e: KeyboardEvent) => {
        // 모달 오픈 시에만 처리
        if (!document.body.classList.contains(MODAL_OPENED_CN)) return

        // PC에서만 동작 가능한 기능. 모바일에서는 useDirectBarcodeScan와 충돌남.
        if (e.key === 'Escape') {
          handleClose()

          setTimeout(() => {
            if ($prevActive.current) $prevActive.current.focus()
          }, 0)
        }
      }

      document.addEventListener('keyup', onKeyupEscape, false)
      return () => document.removeEventListener('keyup', onKeyupEscape, false)
    },
    [closable, handleClose],
  )

  useEffect(
    function onSetActiveElement() {
      if (open) {
        $prevActive.current = document.activeElement as HTMLElement
        if (!$prevActive.current) return

        $prevActive.current.blur()
      }
    },
    [open],
  )

  return (
    <Modal
      closable={closable}
      open={open}
      type={type}
      onConfirm={handleConfirm}
      onClose={handleClose}
      confirmText={confirmText}
      closeText={closeText}
      buttons={buttons}
    >
      <VStack gap={8}>{typeof alertContent === 'string' ? <span dangerouslySetInnerHTML={{ __html: alertContent }} /> : alertContent}</VStack>
    </Modal>
  )
}

export function AlertModalList({ list, destroy }: { list: Omit<AlertModalProps, 'open'>[]; destroy: (id: string) => void }) {
  return (
    <>
      {!list.length
        ? null
        : list.map((alert) => (
            <AlertModal
              open
              closable
              {...alert}
              type="alert"
              key={`moms-alert-modal-${alert.id}`}
              onClose={() => {
                if (alert.onClose) alert.onClose()
                destroy(alert.id)
              }}
            />
          ))}
    </>
  )
}

export function ConfirmModalList({ list, destroy }: { list: Omit<AlertModalProps, 'open'>[]; destroy: (id: string) => void }) {
  return (
    <>
      {list.length
        ? list.map((confirm) => (
            <AlertModal
              open
              {...confirm}
              type="confirm"
              key={`mw-confirm-modal-${confirm.id}`}
              onClose={() => {
                if (confirm.onClose) confirm.onClose()
                destroy(confirm.id)
              }}
              onConfirm={() => {
                if (confirm.onConfirm) confirm.onConfirm()
                destroy(confirm.id)
              }}
            />
          ))
        : null}
    </>
  )
}

export default AlertModal
