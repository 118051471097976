import React, { useCallback, useEffect, useState } from 'react'
import { isNil } from 'lodash-es'
import { HStack } from '@/components/content/Stack'
import FieldLabel from '@/components/content/FieldLabel'
import Radio from '@/components/content/Radio'

interface Props {
  label?: string
  onChange?: (v: boolean) => void
  required?: boolean
  disabled?: boolean
  value?: boolean
}
const RadioBool = ({ label, disabled, onChange, required, value }: Props) => {
  const [selectedValue, setSelectedValue] = useState<string>((value ?? true)?.toString())

  const handleChange = useCallback(
    (v: string) => {
      setSelectedValue(v)
      if (onChange) {
        onChange(v === 'true')
      }
    },
    [onChange],
  )

  useEffect(
    function onCheckValue() {
      if (isNil(value)) return

      setSelectedValue((prev) => {
        const strValue = value?.toString() ?? 'true'
        if (prev !== strValue) return strValue
        return prev
      })
    },
    [value],
  )

  return (
    <HStack>
      {label && <FieldLabel required={required} content={label} />}
      <HStack gap={16}>
        <Radio value="true" disabled={disabled} checked={selectedValue === 'true'} onChange={handleChange} label="Y" />
        <Radio value="false" disabled={disabled} checked={selectedValue === 'false'} onChange={handleChange} label="N" />
      </HStack>
    </HStack>
  )
}

export default RadioBool
