import React, { useMemo } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import GlobalStyles from '@/styles/GlobalStyles'
import AuthChecker from '@/pages/auth/components/AuthChecker'
import MainLayout from '@/components/layout/MainLayout'
import { ROUTES_WITH_ELEMENTS } from '@/utils/routes/routesWithElements'
import AuthWrapper from '@/pages/auth/components/AuthWrapper'
import { omit } from 'lodash-es'
import { LicenseManager } from 'ag-grid-enterprise'
import InlineFramePage from '@/pages/legacy/iframe/index'
import NotFoundLayout from '@/components/layout/NotFoundLayout'
import { useStoreSessionReset } from '@/pages/legacy/hooks/useStoreSessionReset'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_KEY)

function App() {
  useStoreSessionReset()

  const authPages = useMemo(() => ROUTES_WITH_ELEMENTS.auth, [])
  const otherPages = useMemo(() => omit(ROUTES_WITH_ELEMENTS, 'auth'), [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="auth" element={<AuthWrapper />}>
          {Object.entries(authPages).map(([path, element]) => (
            <Route path={path} element={element} key={`moms-auth-route-${path}`} />
          ))}
        </Route>
        <Route path="/old/*" element={<NotFoundLayout />} />
        <Route path="/" element={<MainLayout />}>
          {Object.entries(otherPages).map(([parentKey, childMap]) => (
            <Route path={parentKey} key={`moms-${parentKey}-route`}>
              {Object.entries(childMap).map(([path, element]) => (
                <Route path={path} element={element} key={`moms-${parentKey}-route-${path}`} />
              ))}
            </Route>
          ))}

          <Route path="*" element={<InlineFramePage />} />
        </Route>
      </Routes>

      {/* COMMON */}
      <GlobalStyles />
      <AuthChecker />
    </BrowserRouter>
  )
}

export default App
