import React, { useMemo } from 'react'
import SelectBox, { SelectBoxOpt, SelectBoxProps } from '@/components/content/SelectBox'

interface SelectBoxBoolProps extends Omit<SelectBoxProps, 'value' | 'onChange'> {
  value?: boolean | null
  onChange?: (v: boolean | null) => void
}

function SelectBoxBool({ value, onChange, ...rest }: SelectBoxBoolProps) {
  const options = useMemo<SelectBoxOpt<string>[]>(
    () => [
      { label: '전체', value: 'null' },
      { label: 'Y', value: 'true' },
      { label: 'N', value: 'false' },
    ],
    [],
  )

  const handleChange = (v: string) => {
    if (!onChange) return
    onChange(v === 'true' ? true : v === 'false' ? false : null)
  }

  return <SelectBox<string> placeholder={false} value={value?.toString()} options={options} onChange={handleChange} {...rest} />
}

export default SelectBoxBool
