import { useMutation, useQuery } from '@tanstack/react-query'
import { addDeliveryArea, getCitiesOnDeliveryAreas, getDeliveryAreas, updateDeliveryArea } from '@/services/deliveryAreas/apis'
import { API_COMMON_CACHE_TIME } from '@/utils/consts'
import { GetDeliveryAreasReq } from '@/services/deliveryAreas/types'
import useExcelDownload from '@/hooks/useDownloadExcel'
import { Utils } from '@/utils/Utils'
import request from '@/utils/request'
import { keyBy } from 'lodash-es'

export const PAGE_SIZE_ON_DELIVERY_AREAS = 1000

const PATH = ['delivery-areas']
export const GET_DELIVERY_AREAS = [...PATH, 'GET_DELIVERY_AREAS']
export const GET_CITIES_ON_DELIVERY_AREAS = [...PATH, 'GET_CITIES_ON_DELIVERY_AREAS']

export const useGetCitiesOnDeliveryAreas = () => {
  const { data = [] } = useQuery({
    queryKey: GET_CITIES_ON_DELIVERY_AREAS,
    queryFn: getCitiesOnDeliveryAreas,
    select: ({ data }) => data?.items || [],
    staleTime: API_COMMON_CACHE_TIME,
  })

  const options = data.map((city) => ({ label: city, value: city }))
  return { data, options, keyMap: keyBy(options, (opt) => opt.value) }
}

export const useGetDeliveryAreas = (params: ApiPaginationReq<GetDeliveryAreasReq>) => {
  return useQuery({
    queryKey: [...GET_DELIVERY_AREAS, params],
    queryFn: () => getDeliveryAreas({ ...params, size: PAGE_SIZE_ON_DELIVERY_AREAS }),
    enabled: false,
    select: ({ data }) => ({
      list: data?.content || [],
      totalPages: data?.totalPages || 0,
      totalElements: data?.totalElements || 0,
      currentPage: data?.pageable?.pageNumber || 0,
    }),
    placeholderData: (previousData) => previousData,
  })
}

export const useAddDeliveryArea = () => {
  return useMutation({
    mutationFn: addDeliveryArea,
  })
}

export const useUpdateDeliveryArea = () => {
  return useMutation({
    mutationFn: updateDeliveryArea,
  })
}

// EXCEL
const EXCEL_API_ON_DELIVERY_AREAS = '/api/v1/delivery-areas/excel'
export const useExportExcelOnDeliveryAreas = (params: GetDeliveryAreasReq) => {
  const excelDownload = useExcelDownload()
  return {
    isLoading: excelDownload.isLoading,
    onExportData: () => {
      excelDownload.onDownloadExcel(`${EXCEL_API_ON_DELIVERY_AREAS}${Utils.qsStringify(params)}`)
    },
  }
}

export const useUploadExcelOnDeliveryAreas = () => {
  const { isPending, mutateAsync: onUploadData } = useMutation({
    mutationFn: (file: File) => {
      const formData = new FormData()
      formData.append('file', file)

      return request.post<ApiResponseV2<number>, FormData>(EXCEL_API_ON_DELIVERY_AREAS, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    },
  })

  return {
    isLoading: isPending,
    onUploadData,
  }
}
