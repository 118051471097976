import * as React from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { colorSystem, zIndexSystem } from '@/styles/theme'
import Text from '@/components/content/Text'
import MToast from '@/utils/MToast'
import { HStack } from '@/components/content/Stack'
import RemixIcon from '@/components/content/RemixIcon'

const TOAST_DISPLAYED_MS = 4000

export type ToastKind = 'success' | 'error'
export interface ToastProps {
  id: string
  kind?: ToastKind
  message: string
}

function Toast({ id, kind = 'success', message }: ToastProps) {
  useEffect(() => {
    setTimeout(() => MToast.destory(id), TOAST_DISPLAYED_MS)
  }, [id])

  return (
    <ToastWrap align="center" justify="space-between" p={8} $kind={kind}>
      <HStack align="center" gap={8} p="0 12px 0 0">
        <RemixIcon
          name={kind === 'success' ? 'ri-checkbox-circle-fill' : 'ri-error-warning-fill'}
          size={24}
          color={colorSystem.icon.primary_light}
        />
        <Text color={colorSystem.text.common.inverse}>{message}</Text>
      </HStack>

      <RemixIcon name="ri-close-line" size={24} color={colorSystem.icon.primary_light} onClick={() => MToast.destory(id)} />
    </ToastWrap>
  )
}

export const ToastList = ({ list }: { list: ToastProps[] }) => {
  if (!list) return null

  return (
    <ToastListWrap>
      {list.map((toast) => (
        <Toast key={`toast-list-${toast.id}`} {...toast} />
      ))}
    </ToastListWrap>
  )
}

const ToastListWrap = styled.div`
  padding: 8px;
  position: fixed;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${zIndexSystem.modal + 1};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
`

const ToastWrap = styled(HStack)<{ $kind: ToastKind }>`
  min-width: 350px;
  border-radius: 4px;
  background: ${({ $kind }) => ($kind === 'success' ? colorSystem.text.common.success : colorSystem.text.common.error)};
`

export default Toast
