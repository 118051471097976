import FilterList from '@/components/content/FilterList'
import Input from '@/components/content/Input'
import DateRangePickerInput from '@/components/date/DateRangePickerInput'
import SearchInput from '@/components/system/SearchInput'
import ShipperSelectModal from '@/components/system/ShipperSelectModal'
import { InterfaceHistoryParams } from '@/services/history/history.types'
import { DATE_FORMAT } from '@/utils/consts'
import { AuthStorage } from '@/utils/storage/AuthStorage'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'

export const defaultValues: InterfaceHistoryParams = {
  sprNm: AuthStorage.user.get().SPR_NM ?? '',
  apiDir: '',
  apiNo: '',
  module: '',
  plnDate: [dayjs().format(DATE_FORMAT.dateSimple), dayjs().format(DATE_FORMAT.dateSimple)],
}

interface InterfaceHistoryFormProps {
  onSearch(values: InterfaceHistoryParams): void
}
export const InterfaceHistoryForm = ({ onSearch }: InterfaceHistoryFormProps) => {
  const { setValue, watch, reset, handleSubmit } = useFormContext<InterfaceHistoryParams>()
  const values = watch()

  return (
    <FilterList
      list={[
        [
          {
            label: '화주명',
            value: (
              <ShipperSelectModal
                shipperName={values.sprNm}
                trigger={<SearchInput value={values.sprNm} onChange={(v) => setValue('sprNm', v)} />}
                onSelect={(v) => setValue('sprNm', v.shipper_name)}
              />
            ),
          },
          {
            label: '실행일자',
            required: true,
            value: (
              <DateRangePickerInput
                value={values.plnDate}
                onChange={(v) => {
                  const [start, end] = v
                  setValue('plnDate', [dayjs(start).format(DATE_FORMAT.dateSimple), dayjs(end).format(DATE_FORMAT.dateSimple)])
                }}
              />
            ),
          },
          {
            label: '통신방향',
            value: <Input value={values.apiDir} onChange={(v) => setValue('apiDir', v)} />,
          },
        ],
        [
          {
            label: 'API 번호',
            value: <Input value={values.apiNo} onChange={(v) => setValue('apiNo', v)} />,
          },
        ],
      ]}
      onReset={() => reset(defaultValues)}
      onSearch={handleSubmit(onSearch)}
    />
  )
}
