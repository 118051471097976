import React, { CSSProperties, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { colorSystem, fontSystem } from '@/styles/theme'

export interface TextProps {
  className?: string
  block?: boolean
  color?: string
  style?: CSSProperties
  kind?: keyof typeof fontSystem
  role?: string
  title?: string
  onClick?: () => void
}

function Text({ className, kind = 'Body_14_medium', block, color, style, children, onClick, title, role }: PropsWithChildren<TextProps>) {
  return (
    <TextWrap
      className={className}
      kind={kind}
      $block={block}
      color={color}
      style={style}
      onClick={onClick}
      title={title}
      role={onClick ? 'button' : role}
    >
      {children}
    </TextWrap>
  )
}

const TextWrap = styled.span<Omit<TextProps, 'block' | 'kind'> & { $block?: boolean; kind: keyof typeof fontSystem }>`
  display: ${({ $block }) => ($block ? 'flex' : 'inline-flex')};
  color: ${({ color }) => color || colorSystem.text.common.primary};
  word-break: keep-all;
  align-items: center;
  vertical-align: middle;

  ${({ kind }) => fontSystem[kind]};
`

export default Text
