import React, { forwardRef, HTMLInputTypeAttribute, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { borderRadius, colors, colorSystem, fontSystem } from '@/styles/theme'
import { HStack, VStack } from '@/components/content/Stack'
import Text from '@/components/content/Text'

export interface InputProps {
  id?: string
  className?: string
  label?: string
  block?: boolean
  disabled?: boolean
  autoFocus?: boolean
  type?: HTMLInputTypeAttribute
  value?: string | number
  placeholder?: string
  onChange?: (v: string) => void
  onEnter?: (v: string) => void
  onClick?: () => void
  regex?: RegExp
  readOnly?: boolean
  helpText?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      className,
      label,
      type = 'text',
      block = false,
      disabled,
      autoFocus,
      readOnly = false,
      regex,
      value,
      placeholder,
      onEnter,
      onChange,
      onClick,
      helpText,
    },
    ref,
  ) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e) e.preventDefault()
        if (onChange) {
          const value = e.target.value || ''
          if (!value) {
            onChange(value)
            return
          }

          if (regex) {
            onChange(value.replace(regex, ''))
          } else if (type === 'number') {
            onChange(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
          } else {
            onChange(value)
          }
        }
      },
      [type, onChange],
    )

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
          e.preventDefault()
          onEnter(value?.toString() ?? '')
        }
      },
      [onEnter],
    )

    return (
      <InputWrap block className={className} $block={block} gap={8}>
        {label && <InputLabel>{label}</InputLabel>}
        <VStack block>
          <StyledInput
            ref={ref}
            id={id}
            type={type === 'number' ? 'tel' : type || 'text'}
            value={value}
            disabled={disabled}
            autoFocus={autoFocus}
            readOnly={readOnly}
            placeholder={placeholder}
            onChange={handleChange}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            $error={!!helpText}
          />
          {helpText && (
            <InputHelpText kind="Body_12_medium" color={colors.red500}>
              {helpText}
            </InputHelpText>
          )}
        </VStack>
      </InputWrap>
    )
  },
)

const InputWrap = styled(HStack)<{ $block?: InputProps['block'] }>`
  ${({ $block }) =>
    !$block &&
    css`
      width: auto;
    `}
`

const InputLabel = styled.label`
  display: flex;
  align-items: center;
  width: 88px;
  height: 28px;
  text-align: left;
  ${fontSystem.Body_14_medium};
`

const StyledInput = styled.input<{ $error?: boolean }>`
  width: 100%;
  height: 28px;
  padding: 3px 8px 0;
  border: 1px solid ${colorSystem.border.element};
  color: ${colorSystem.text.common.primary};
  ${borderRadius.sm}
  ${fontSystem.Body_14_regular};

  &::placeholder {
    color: ${colorSystem.text.common.tertiary};
  }

  &:focus {
    border: 1px solid ${colorSystem.border.element_active};
    color: ${colorSystem.text.common.primary};

    ${({ $error }) =>
      $error &&
      css`
        border: 1px solid ${colorSystem.text.common.error};
      `}
  }

  &:read-only {
    cursor: default;
    background-color: ${colorSystem.bg.input.disabled};
  }

  &:disabled {
    background-color: ${colorSystem.bg.input.disabled};
  }
`

const InputHelpText = styled(Text)`
  margin-top: 4px;
`

export default Input
