import React from 'react'
import { AlertModalProps, ConfirmModalList } from '@/components/content/Alert'
import { createRoot, Root } from 'react-dom/client'
import { Utils } from '@/utils/Utils'

const CONFIRM_ID = 'moms-confirm'

class MConfirmAction {
  list: Omit<AlertModalProps, 'open'>[] = []

  root: Root | null = null

  constructor() {
    const $wrapper = document.getElementById(CONFIRM_ID)
    if (!$wrapper) return

    this.root = createRoot($wrapper)
  }

  destroy = (id: string) => {
    const deletedIdx = this.list.findIndex((confirm) => confirm.id === id)
    this.list.splice(deletedIdx, 1)

    if (this.root) {
      this.root.render(this.list.length ? <ConfirmModalList list={this.list} destroy={this.destroy} /> : null)
    }
  }

  show = (content: React.ReactNode, props: Pick<AlertModalProps, 'onClose' | 'onConfirm'>) => {
    if (!this.list.some((alert) => alert.content === content)) {
      this.list.push({ ...props, content, id: Utils.uuid() })
    }

    if (this.root) {
      this.root.render(<ConfirmModalList list={this.list} destroy={this.destroy} />)
    }
  }
}

const MConfirm = new MConfirmAction()
export default MConfirm
