import React, { useCallback, useMemo } from 'react'
import SelectBox, { SelectBoxOpt } from '@/components/content/SelectBox'
import { ApiStorage, ApiType } from '@/utils/storage/ApiStorage'
import { DEV_API_URL } from '@/utils/consts'
import { Utils } from '@/utils/Utils'

function SelectBoxApi() {
  const apiTypeOptions = useMemo<SelectBoxOpt<ApiType>[]>(
    () => Object.keys(DEV_API_URL).map((type) => ({ label: type, value: type as ApiType })),
    [],
  )

  const onChangeApiType = useCallback((v: ApiType) => {
    ApiStorage.apiType.set(v)
    window.location.reload()
  }, [])

  if (Utils.isProd()) {
    return null
  }
  return <SelectBox<ApiType> value={ApiStorage.apiType.get()} options={apiTypeOptions} onChange={onChangeApiType} />
}

export default SelectBoxApi
