import { createGlobalStyle } from 'styled-components'
import { colors, MODAL_OPENED_CN } from '@/styles/theme'

const GlobalStyles = createGlobalStyle<{ $isWindows?: boolean }>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "notokr", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }

  html, body {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 1.125;
    font-weight: 500;
    background-color: ${colors.black};
    -webkit-text-size-adjust: none;
  }

  #root {
    height: 100%;
  }
  
  a {
    color: inherit;
    
    &:visited,
    &:focus,
    &:hover {
      color: inherit;
    }
  }

  [role='button'], [role='link'] {
    cursor: pointer;
  }

  body.${MODAL_OPENED_CN} {
    overflow: hidden;
  }

  mark {
    padding: 4px 2px;
    background: ${colors.red500};
    color: ${colors.white};
  }
  
  // ICON
  [class^="ri-"], [class*=" ri-"] {
    &[data-disabled='true'] {
      cursor: not-allowed !important;
      color: ${colors.gray300} !important;
      pointer-events: none !important;
    }
  }
`

export default GlobalStyles
