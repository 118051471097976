import { ColDef, GridOptions } from 'ag-grid-community'
import dayjs from 'dayjs'
import { AgGridReactProps } from 'ag-grid-react'
import { isNil } from 'lodash-es'
import { DATE_FORMAT } from '@/utils/consts'
import { Utils } from '@/utils/Utils'
import { colors } from '@/styles/theme'

export const agGridOptions: AgGridReactProps = {
  stopEditingWhenCellsLoseFocus: true,
  suppressRowClickSelection: true,
  enableRangeSelection: true,
  suppressScrollOnNewData: true,
  suppressDragLeaveHidesColumns: true,
  suppressCsvExport: true,
  undoRedoCellEditing: true,
  undoRedoCellEditingLimit: 20,
}

export const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
}

export const gridOptions: GridOptions = {
  onRowDataUpdated: (params) => {
    if (params.api.getDisplayedRowCount() === 0) params.api.showNoRowsOverlay()
  },
  // Custom Types
  dataTypeDefinitions: {
    decimal: {
      baseDataType: 'number',
      extendsDataType: 'number',
      columnTypes: 'alignRight',
      valueFormatter: ({ value }) => (!isNil(value) ? Utils.getCommaNum(value) : ''),
    },
    integerDecimal: {
      baseDataType: 'number',
      extendsDataType: 'number',
      columnTypes: 'alignRight',
      valueFormatter: ({ value }) => (!isNil(value) ? Utils.getCommaNum(Math.floor(value)) : ''),
    },
    yn: {
      baseDataType: 'text',
      extendsDataType: 'text',
      valueFormatter: ({ value }) => (value ? 'Y' : 'N'),
    },
    yna: {
      baseDataType: 'object',
      extendsDataType: 'object',
      valueFormatter: ({ value }) => {
        if (value === null) return '전체'
        return value ? 'Y' : 'N'
      },
    },
    date: {
      baseDataType: 'text',
      extendsDataType: 'text',
      valueFormatter: (params) => (!params.value ? '' : dayjs(params.value).format(DATE_FORMAT.date)),
    },
    dateNday: {
      baseDataType: 'text',
      extendsDataType: 'text',
      valueFormatter: (params) => (!params.value ? '' : dayjs(params.value).format(DATE_FORMAT.dateNday)),
    },
    datetime: {
      baseDataType: 'text',
      extendsDataType: 'text',
      valueFormatter: (params) => {
        return !params.value ? '' : dayjs(params.value).format(DATE_FORMAT.datetime)
      },
    },
    dateMonth: {
      baseDataType: 'text',
      extendsDataType: 'text',
      valueFormatter: (params) => {
        return !params.value ? '' : dayjs(params.value).format(DATE_FORMAT.dateMonth)
      },
    },
    time: {
      baseDataType: 'text',
      extendsDataType: 'text',
      valueFormatter: (params) => {
        return !params.value ? '' : `${params.value.slice(0, 2)}:${params.value.slice(2, 4)}:${params.value.slice(4, 6)}`
      },
    },
  },
  // Custom Types
  columnTypes: {
    checkbox: {
      width: 110,
      cellEditor: 'agCheckboxCellEditor', // Displayed only when editable
      cellRenderer: 'agCheckboxCellRenderer',
    },
    alignRight: {
      cellStyle: { textAlign: 'right' },
    },
    link: {
      // @ts-ignore
      cellRenderer: ({ value }) =>
        value ? (
          <a href={value} target="_blank" rel="noreferrer noopener" style={{ color: colors.blue500, textDecoration: 'underline' }}>
            {value}
          </a>
        ) : null,
    },
  },
}
