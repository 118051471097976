import React, { useEffect } from 'react'
import { useCheckUser } from '@/services/auth/useAuthQueries'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthStorage } from '@/utils/storage/AuthStorage'
import { PATHS } from '@/utils/routes/paths'
import useLoginSuccessFlow from '@/pages/auth/useLoginSuccessFlow'
import { AuthUtils } from '@/utils/AuthUtils'

function AuthChecker() {
  const location = useLocation()
  const navigate = useNavigate()
  const checkUser = useCheckUser()
  const { onCheckAuthFlow } = useLoginSuccessFlow()

  useEffect(function onCheckToken() {
    /**** 비로그인 *****/
    const authToken = AuthStorage.auth.get()
    const isOnAuth = location.pathname.startsWith(PATHS.auth)

    // 비로그인 유저가 일반 페이지에 있는 경우
    if (!authToken && location.pathname !== PATHS['auth.login'] && location.pathname !== PATHS['auth.reset-otp']) {
      navigate(PATHS['auth.login'], { replace: true })
      return
    }

    /***** 로그인 *****/
    // 로그인 했고 메인페이지인 경우
    if (!isOnAuth) {
      // 토큰이 유효한지 체크
      checkUser.mutateAsync().catch(() => {
        alert('토큰이 유효하지 않습니다.')
        AuthUtils.logout()
      })
    }

    onCheckAuthFlow()
  }, [])

  return <></>
}

export default AuthChecker
