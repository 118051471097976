import { inRange } from 'lodash-es'
import { AddDeliveryAreaReq } from '@/services/deliveryAreas/types'
import { DA_CITY_WITH_NULLABLE_DISTRICT, DA_MAX_SIZE_DISTRICT } from '@/pages/master/deliveryAreas/components/consts'
import MToast from '@/utils/MToast'
import { REGEX } from '@/utils/consts'

export class DeliveryAreasUtils {
  static validateFields = (formData: AddDeliveryAreaReq) => {
    return (
      DeliveryAreasUtils.checkErrorOfCity(formData) &&
      DeliveryAreasUtils.checkErrorOfZipCode(formData) &&
      DeliveryAreasUtils.checkErrorOfDistrict(formData) &&
      DeliveryAreasUtils.checkErrorOfTown(formData) &&
      DeliveryAreasUtils.checkErrorOfDeliveryLead(formData)
    )
  }

  static checkErrorOfZipCode = (formData: Pick<AddDeliveryAreaReq, 'zipcode'>) => {
    if (!formData.zipcode) {
      MToast.error(`우편번호를 한글자 이상 입력해주세요`)
      return false
    }

    if (!REGEX.positiveNumWithZero.test(formData.zipcode)) {
      MToast.error(`우편번호는 숫자만 입력해주세요.`)
      return false
    }

    return true
  }

  static checkErrorOfCity = (formData: Pick<AddDeliveryAreaReq, 'city'>) => {
    if (!formData.city) {
      MToast.error(`시구분을 선택해주세요`)
      return false
    }

    return true
  }

  static checkErrorOfDistrict = (formData: Pick<AddDeliveryAreaReq, 'district' | 'city'>) => {
    // 세종시는 구구분 빈값 처리 가능.
    const isNullableDistrict = formData.city === DA_CITY_WITH_NULLABLE_DISTRICT
    if (!isNullableDistrict && !formData.district) {
      MToast.error(`구구분을 한글자 이상 입력해주세요`)
      return false
    }

    if (!isNullableDistrict && !REGEX.korean.test(formData.district!)) {
      MToast.error(`구구분은 한글만 입력해주세요`)
      return false
    }

    if (formData.district?.length! > DA_MAX_SIZE_DISTRICT) {
      MToast.error(`구구분은 ${DA_MAX_SIZE_DISTRICT} 이하로 적어주세요.`)
      return false
    }

    return true
  }

  static checkErrorOfTown = (formData: Pick<AddDeliveryAreaReq, 'town'>) => {
    if (!formData.town) {
      MToast.error(`읍면동을 한글자 이상 입력해주세요`)
      return false
    }

    if (!REGEX.korean.test(formData.town!)) {
      MToast.error(`읍면동은 한글만 입력해주세요`)
      return false
    }

    if (formData.town.length > DA_MAX_SIZE_DISTRICT) {
      MToast.error(`읍면동은 ${DA_MAX_SIZE_DISTRICT} 이하로 적어주세요.`)
      return false
    }

    return true
  }

  static checkErrorOfDeliveryLead = (formData: Pick<AddDeliveryAreaReq, 'remoteArea' | 'deliveryLeadDay'>) => {
    const range = formData.remoteArea ? [0, 9] : [1, 3] // 도서 산간인 경우 0~9, 기본은 1~3
    if (!inRange(formData.deliveryLeadDay, range[0], range[1] + 1)) {
      MToast.error([formData.remoteArea ? `도서산간인 경우` : '도서산간이 아닌 경우', `${range.join('~')} 사이로 입력해주세요.`].join(' '))
      return false
    }

    return true
  }
}
