import { useCallback, useState } from 'react'

export interface UseModalProps {
  open: boolean
  onOpenModal: () => void
  onCloseModal: () => void
  onToggleModal: () => void
}

const useModal = (initOpen?: boolean) => {
  const [open, setOpen] = useState(initOpen ?? false)

  const onOpenModal = useCallback(() => setOpen(true), [])
  const onCloseModal = useCallback(() => setOpen(false), [])
  const onToggleModal = useCallback(() => setOpen((prev) => !prev), [])

  return { open, onOpenModal, onCloseModal, onToggleModal }
}

export default useModal
