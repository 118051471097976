import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { borderRadius, colorSystem, fontSystem } from '@/styles/theme'

export interface SelectBoxOpt<T> {
  label: string
  value: T
}

export interface SelectBoxProps<T = string> {
  hasAll?: boolean
  block?: boolean
  className?: string
  placeholder?: string | false
  value?: T
  options?: SelectBoxOpt<T>[]
  onChange?: (v: T) => void
  onClick?: React.MouseEventHandler
}

const EMPTY_VAL = 'EMPTY'
const ALL_VAL = ''

function SelectBox<T extends string | number>({
  block,
  hasAll,
  className,
  placeholder,
  value,
  options = [],
  onChange,
  onClick,
}: SelectBoxProps<T>) {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!onChange) return
    onChange(e.target.value as T)
  }, [])

  return (
    <SelectBoxWrap className={className} $block={block} value={value ?? EMPTY_VAL} onChange={handleChange} onClick={onClick}>
      {placeholder !== false && (
        <option disabled value={EMPTY_VAL} unselectable="on">
          {placeholder || '값을 선택해주세요'}
        </option>
      )}
      {hasAll && <option value={ALL_VAL}>전체</option>}
      {options?.map((opt) => (
        <option key={`select-box-opt-${opt.value}`} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </SelectBoxWrap>
  )
}

const SelectBoxWrap = styled.select<{ $block?: boolean }>`
  height: 28px;
  padding: 3px 8px 0;
  border: 1px solid ${colorSystem.border.element};
  color: ${colorSystem.text.common.primary};
  ${borderRadius.sm}
  ${fontSystem.Body_14_regular};

  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
    `}

  &:focus {
    border: 1px solid ${colorSystem.border.element_active};
    color: ${colorSystem.text.common.primary};
  }

  &:disabled {
    background-color: ${colorSystem.bg.input.disabled};
  }

  /* Arrow */
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 9px auto;
`

export default SelectBox
