import React, { ReactElement, useCallback, useState } from 'react'
import useModal from '@/hooks/useModal'
import Modal from '@/components/content/Modal'
import { useGetShipperList } from '@/services/shipper/useShipperQueries'
import Input from '@/components/content/Input'
import Table from '@/components/content/Table'
import { ShipperSearchItem } from '@/services/shipper/types'
import MAlert from '@/utils/MAlert'
import { VStack } from '@/components/content/Stack'

// *** 주의사항 ***
// trigger 은 onClick props를 받아서 처리 가능해야 합니다.

interface ShipperSelectModalProps {
  trigger: React.ReactNode
  title?: string
  shipperName?: string
  onSelect: (params: ShipperSearchItem) => void //?
}

function ShipperSelectModal({ title = '화주사 검색', shipperName: sprName, trigger, onSelect }: ShipperSelectModalProps) {
  const { open, onOpenModal, onCloseModal } = useModal()

  const [shipperName, setShipperName] = useState(sprName)
  const [selectedIdx, setSelectedIdx] = useState(-1)

  const getShipperList = useGetShipperList(shipperName)
  const list = getShipperList.data.list || []

  const onSearch = useCallback(() => {
    setSelectedIdx(-1)
    getShipperList.refetch()
  }, [])

  const onSubmit = () => {
    if (selectedIdx === -1) {
      return MAlert.show('소속을 선택해주세요')
    }

    onSelect(list[selectedIdx])
    onCloseModal()
  }

  return (
    <>
      {trigger
        ? React.cloneElement(trigger as ReactElement, {
            onClick: onOpenModal,
          })
        : null}
      {open && (
        <Modal
          open
          size="md"
          title={title}
          closable={false}
          buttons={[
            { kind: 'secondary', content: '닫기', onClick: onCloseModal },
            { kind: 'primary_light', content: '선택 완료', disabled: selectedIdx === -1, onClick: onSubmit },
          ]}
          onClose={onCloseModal}
        >
          <VStack gap={18}>
            <Input label="화주명" value={shipperName} placeholder="화주명을 입력해주세요" onChange={setShipperName} onEnter={onSearch} />
            <Table<ShipperSearchItem>
              block
              data={list}
              emptyText="화주명을 검색해주세요"
              onClickRow={(row, idx) => setSelectedIdx(idx)}
              columns={[
                { width: 30, render: (_, row, idx) => <input type="radio" checked={idx === selectedIdx} onChange={() => setSelectedIdx(idx)} /> },
                { key: 'shipper_name', title: '화주명', width: 170 },
                { key: 'shipper_code', title: '화주코드', width: 100 },
              ]}
            />
          </VStack>
        </Modal>
      )}
    </>
  )
}

export default ShipperSelectModal
