import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { colorSystem, fontSystem } from '@/styles/theme'

export const LABEL_WIDTH_DEFAULT = 88

interface Props {
  width?: number
  className?: string
  required?: boolean
  content?: React.ReactNode
}

function FieldLabel({ className, width, required, content }: Props) {
  const labelContent = useMemo(() => {
    if (content && typeof content === 'string') {
      return content.replace(/\n/g, '<br/>')
    }

    return content
  }, [content])

  return (
    <Label className={className} required={required} width={width}>
      {typeof labelContent === 'string' ? <span dangerouslySetInnerHTML={{ __html: labelContent }} /> : labelContent}
    </Label>
  )
}

const Label = styled.div<Props>`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  max-width: ${({ width }) => width || LABEL_WIDTH_DEFAULT}px;
  min-width: ${({ width }) => width || LABEL_WIDTH_DEFAULT}px;
  word-break: keep-all;
  ${fontSystem.Body_14_medium};

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: '*';
        display: inline-block;
        margin-left: 2px;
        padding-top: 6px;
        color: ${colorSystem.text.common.highlight};
      }
    `}
`

export default FieldLabel
