import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FOOTER_HEIGHT } from '@/utils/consts'

const FOOTER_CONTENT_LIST = [
  {
    label: 'MUSINSA LOGISTICS',
    link: 'https://www.musinsalogistics.co.kr/',
  },
  {
    label: 'MUSINSA WMS',
    link: 'https://wms.musinsalogistics.co.kr/',
  },
  {
    label: 'PRIVACY POLICY',
    link: 'https://www.musinsalogistics.co.kr/privacy/privacy.html',
  },
  {
    label: `ⓒ 2023. MUSINSA LOGISTICS Corp. All Rights Reserved`,
  },
]

function MainFooter() {
  return (
    <FooterWrapper>
      <nav>
        {FOOTER_CONTENT_LIST.map(({ label, link }) => (
          <li key={`footer-content-${label}-${link}`}>
            {link ? (
              <Link to={link} target="_blank">
                {label}
              </Link>
            ) : (
              label
            )}
          </li>
        ))}
      </nav>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${FOOTER_HEIGHT}px;
  background-color: #000;

  > nav {
    list-style: none;

    > li {
      float: left;
      padding: 0 20px;
      font-size: 12px;
      line-height: 16px;
      text-decoration: none;
      color: #929292;
      cursor: default;

      a,
      span {
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }
    }
  }
`

export default MainFooter
