import * as React from 'react'
import styled from 'styled-components'
import { HStack, VStack } from '@/components/content/Stack'
import FieldLabel, { LABEL_WIDTH_DEFAULT } from '@/components/content/FieldLabel'
import { CSSProperties, useCallback } from 'react'
import { max } from 'lodash-es'

const FIELD_GAP = 40

export interface FieldListProps {
  className?: string
  list: { width?: number | string; label?: React.ReactNode; value: React.ReactNode; required?: boolean }[][]
}

function FieldList({ className, list }: FieldListProps) {
  const getItemWidth = useCallback(() => {
    const maxFieldSize = Math.floor(max(list.map((fields) => fields.length)) || 1)
    return `calc(${100 / maxFieldSize}% - ${(FIELD_GAP * (maxFieldSize - 1)) / maxFieldSize}px)`
  }, [list])

  return (
    <FieldListWrap className={className} gap={12} p="12px 20px">
      {list.map((fields, fieldsIdx) => (
        <FieldRow align="baseline" gap={FIELD_GAP} key={`field-list-row-${fieldsIdx}`}>
          {fields?.map((field, fieldIdx) => {
            const style: CSSProperties = {}

            const width = field.width ? (typeof field.width === 'number' ? `${field.width}px` : field.width) : getItemWidth()
            style.minWidth = width
            style.maxWidth = width

            return (
              <FilterItem key={`field-list-item-${field?.label}-${fieldIdx}`} style={style}>
                {field?.label && <FieldLabel required={field.required} content={field.label} />}
                <FilterValue>{field.value}</FilterValue>
              </FilterItem>
            )
          })}
        </FieldRow>
      ))}
    </FieldListWrap>
  )
}

const FieldListWrap = styled(VStack)``

const FieldRow = styled(HStack)``

const FilterItem = styled(HStack)``

const FilterValue = styled.div`
  width: calc(100% - ${LABEL_WIDTH_DEFAULT}px - 16px);
`

export default FieldList
