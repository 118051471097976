import { useMutation, useQuery } from '@tanstack/react-query'
import { callToAddLog, callToGetExportCombo } from '@/services/excel/apis'
import { API_COMMON_CACHE_TIME } from '@/utils/consts'
import { AuthStorage } from '@/utils/storage/AuthStorage'
import { useLocation } from 'react-router-dom'

const PATH = ['excel']
const GET_DOWNLOAD_COMBO = [...PATH, 'GET_DOWNLOAD_COMBO']

export const useGetExportCombo = () =>
  useQuery({
    queryKey: GET_DOWNLOAD_COMBO,
    queryFn: callToGetExportCombo,
    staleTime: API_COMMON_CACHE_TIME,
  })

export const useCompleteExport = () => {
  const { pathname } = useLocation()
  const menuCode = `${pathname.split('/').at(-1)?.toUpperCase()}_HEAD`
  const params = {
    MENU_CD: menuCode,
    SPR_NM: AuthStorage.user.get().SPR_NM || '',
    USR_ID: AuthStorage.user.get().email || '',
    API_NM: 'ExportExcelFunc',
    REMARK: '',
  }

  return useMutation({
    mutationFn: (remark: string) => callToAddLog({ ...params, REMARK: remark }),
  })
}
