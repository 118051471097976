import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import MainFooter from '@/components/layout/MainFooter'
import AuthContent from '@/pages/auth/components/AuthContent'
import { Utils } from '@/utils/Utils'
import SelectBoxApi from '@/components/layout/SelectBoxApi'
import { useRedirectAuth } from '@/pages/legacy/hooks/useRedirectAuth'

function AuthWrapper() {
  useRedirectAuth()

  return (
    <AuthWrapperWithBg>
      <AuthContent>
        <Outlet />
        <SelectBoxApi />
      </AuthContent>
      <MainFooter />
    </AuthWrapperWithBg>
  )
}

const AuthWrapperWithBg = styled.div`
  background-image: url('${Utils.assetUrl('/login_bg_1.jpg')}');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 62px;
  padding-bottom: 42px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export default AuthWrapper
