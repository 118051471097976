import { createContext, ReactNode, useCallback, useContext, useState } from 'react'

import { MenuStorage } from '@/utils/storage/MenuStorage'
import { MenuOnSession } from '@/services/auth/menu.types'

interface MenuOnSessionProviderProps {
  children?: ReactNode
}
interface MenuOnSessionContextValue {
  list: MenuOnSession[]
  reload(): void
}
const MenuOnSessionContext = createContext<MenuOnSessionContextValue>({
  list: [],
  reload: () => {},
})

export const MenuOnSessionProvider = ({ children }: MenuOnSessionProviderProps) => {
  const [menuOnSession, setMenuOnSession] = useState(MenuStorage.menuOnSession.get())

  const reload = useCallback(() => {
    setMenuOnSession(MenuStorage.menuOnSession.get())
  }, [])

  const value = {
    list: menuOnSession,
    reload,
  }
  return <MenuOnSessionContext.Provider value={value}>{children}</MenuOnSessionContext.Provider>
}

export const useMenuOnSession = () => {
  return useContext(MenuOnSessionContext)
}
