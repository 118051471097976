import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { SIDEBAR_OPENED_WIDTH } from '@/components/layout/MainSideBar'
import { HEADER_HEIGHT } from '@/utils/consts'
import { AuthStorage } from '@/utils/storage/AuthStorage'
import MConfirm from '@/utils/MConfirm'
import { AuthUtils } from '@/utils/AuthUtils'
import RemixIcon from '@/components/content/RemixIcon'
import SelectBoxApi from '@/components/layout/SelectBoxApi'
import { PATHS } from '@/utils/routes/paths'
import Button from '@/components/content/Button'
import Modal from '@/components/content/Modal'
import { UserClient } from '@/services/auth/login.types'
import { MenuStorage } from '@/utils/storage/MenuStorage'
import { useGetMenuList } from '@/services/auth/useAuthQueries'

function MainHeader() {
  const navigate = useNavigate()
  const userInfo = useMemo(() => AuthStorage.user.get(), [])
  const { refetch: menuRefetch } = useGetMenuList()

  // 화주 전환 기능 다이얼로그
  const [visibleConvert, setVisibleConvert] = useState(false)
  const onConvert = useCallback((clientInfo: UserClient) => {
    const { SHIPPER_CODE, SHIPPER_NAME } = clientInfo
    const levelMatch = /[\d]*$/
    const authLevel = userInfo.auth?.match(levelMatch)?.[0]
    const newAuthCd = authLevel ? SHIPPER_CODE + authLevel : SHIPPER_CODE

    AuthStorage.user.set({
      ...userInfo,
      auth: newAuthCd,
      AUTH_CD: newAuthCd,
      company: SHIPPER_CODE,
      shipper_name: SHIPPER_NAME ?? SHIPPER_CODE,
      shipper_code: SHIPPER_CODE,
    })
    MenuStorage.menu.clear()
    MenuStorage.menuOnSession.clear()
    menuRefetch().finally(() => {
      navigate('/')
      setTimeout(() => window.location.reload(), 300)
    })
  }, [])

  return (
    <HeaderWrapper>
      <HeaderLogo to="/">
        MOMS
        <span>Musinsa Order Management System</span>
      </HeaderLogo>
      <HeaderInfo>
        <SelectBoxApi />
        {userInfo.nickname}
        <Button kind="primary_light" fit size="sm" onClick={() => setVisibleConvert(true)} disabled={!userInfo.is_convertible}>
          {userInfo.shipperName}
        </Button>
        <Button kind="primary_light" fit size="sm" onClick={() => navigate(PATHS['auth.password'])}>
          비밀번호 변경
        </Button>
        <RemixIcon name="ri-lock-line" onClick={() => MConfirm.show('로그아웃 하시겠어요?', { onConfirm: AuthUtils.logout })} />
      </HeaderInfo>
      <Modal open={visibleConvert} closable size="md" closeIcon title="화주사 변경" onClose={() => setVisibleConvert(false)}>
        <ClientListWrap>
          {userInfo.clientList?.map((client) => (
            <Button
              kind={userInfo.shipperCode === client.SHIPPER_CODE ? 'primary_light' : 'secondary'}
              fit
              size="lg"
              key={client.SHIPPER_CODE}
              onClick={() => onConvert(client)}
            >
              {client.SHIPPER_NAME}
            </Button>
          ))}
        </ClientListWrap>
      </Modal>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  background-color: #000;
  padding: 0 20px;
  color: #fff;
`

const HeaderLogo = styled(Link)`
  display: flex;
  padding-right: 20px;
  width: ${SIDEBAR_OPENED_WIDTH - 20}px;
  font-size: 24px;
  line-height: 24px;
  flex-direction: column;
  text-decoration: none;
  text-align: center;

  > span {
    font-size: 10px;
    line-height: 22px;
    text-decoration: none;
  }
`

const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 18px;
  font-size: 14px;
  line-height: 14px;
`

const ClientListWrap = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  * {
    flex: 1 1 30%;
  }
`

export default MainHeader
