import React from 'react'
import { DatePickerValue, DateRangeValue, VariableDatePickerValue } from '@/components/date/date.types'
import dayjs from 'dayjs'
import DatePicker, { DatePickerProps, registerLocale } from 'react-datepicker'
import { ko } from 'date-fns/locale/ko'
import 'react-datepicker/dist/react-datepicker.css'
import { DateRangePickerWrap } from '@/components/date/date_styles'
import { dateDefaultOptions } from '@/components/date/date_options'

registerLocale('ko', ko)

interface Props extends Omit<DatePickerProps, 'value' | 'onChange' | 'onSelect' | 'excludeScrollbar'> {
  value?: DateRangeValue
  onChange?: (v: DateRangeValue) => void
}

function DateRangePickerInput({ dateFormat, showMonthYearPicker, value, onChange }: Props) {
  const getValidDate = (v: VariableDatePickerValue): DatePickerValue => (dayjs(v).isValid() ? dayjs(v).toDate() : null)

  const [start, end] = value ?? []
  const startDate = getValidDate(start) ?? undefined
  const endDate = getValidDate(end) ?? undefined

  return (
    <DateRangePickerWrap>
      <DatePicker
        {...dateDefaultOptions}
        dateFormat={dateFormat}
        yearClassName={(date) => `${date}년`}
        selectsStart
        selected={startDate}
        onChange={(date) => onChange?.([date, endDate])}
        onSelect={(date) => onChange?.([date, endDate])}
        startDate={startDate}
        endDate={endDate}
        showMonthYearPicker
      />
      -
      <DatePicker
        {...dateDefaultOptions}
        dateFormat={dateFormat}
        selectsEnd
        selected={endDate}
        onChange={(date) => onChange?.([startDate, date])}
        onSelect={(date) => onChange?.([startDate, date])}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showMonthYearPicker
      />
    </DateRangePickerWrap>
  )
}

export default DateRangePickerInput
