import React, { CSSProperties, useMemo } from 'react'
import FieldLabel from './FieldLabel'
import { colors, colorSystem, fontSystem } from '@/styles/theme'
import styled from 'styled-components'
import { Utils } from '@/utils/Utils'

interface FormTableColumn {
  label?: string
  tooltip?: string
  value: React.ReactNode
  inputStyle?: CSSProperties
  required?: boolean
}

interface FormTableProps {
  className?: string
  list: FormTableColumn[][]
  labelWidth?: number
}

const FormTable = (props: FormTableProps) => {
  const { className, list, labelWidth } = props
  const tableId = useMemo(Utils.uuid, [])

  return (
    <form onSubmit={(e) => e.preventDefault()} style={{ padding: '0px' }}>
      <TableStyled className={className}>
        <tbody>
          {list.map((rows, rowIndex) => (
            <tr key={`table-row-${tableId}-${rowIndex}`}>
              {rows.map((cell, cellIndex) => (
                <React.Fragment key={`table-cell-${rowIndex}-${cellIndex}`}>
                  <td className="label-cell">
                    <FieldLabel required={cell.required} content={cell.label} width={labelWidth} />
                  </td>
                  <td colSpan={rows.length === 1 ? 3 : cell.label ? 1 : 2}>{cell.value}</td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </TableStyled>
    </form>
  )
}

const TableStyled = styled.table<{ $block?: boolean; $noHover?: boolean }>`
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  background-color: ${colors.white};
  border: 1px solid ${colorSystem.border.divider};
  border-bottom: 0;

  overflow: auto;
  width: 100%;
  height: 100%;

  > tbody > tr > td {
    padding: 8px;
    color: ${colorSystem.text.table.cell};
    ${fontSystem.Body_12_regular};

    &.empty-text {
      text-align: center;
    }

    border-bottom: 1px solid ${colorSystem.border.divider};
  }

  > tbody > tr > td.label-cell {
    width: 140px;
    padding-left: 16px;
    background-color: ${colorSystem.bg.table.column};
    border-right: 1px solid ${colorSystem.border.divider};

    &:not(:first-child) {
      border-left: 1px solid ${colorSystem.border.divider};
    }
  }
`
export default FormTable
