import React from 'react'
import styled, { css } from 'styled-components'
import { zIndexSystem } from '@/styles/theme'

interface LoaderProps {
  className?: string
  p?: number
  m?: number
  dimmed?: boolean
  position?: 'fixed-center' | 'h-center'
}

function Loader({ className, position, dimmed, p, m }: LoaderProps) {
  return (
    <>
      {dimmed ? (
        <LoaderDimmed>
          <LoaderWrap className={className} position={position} p={p} m={m}>
            <Spinner />
          </LoaderWrap>
        </LoaderDimmed>
      ) : (
        <LoaderWrap className={className} position={position} p={p} m={m}>
          <Spinner />
        </LoaderWrap>
      )}
    </>
  )
}

const LoaderWrap = styled.div<LoaderProps>`
  margin: ${({ m }) => m ?? 0}px;
  padding: ${({ p }) => p ?? 0}px;

  ${({ position }) => {
    if (position === 'h-center') {
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
      `
    }

    if (position === 'fixed-center') {
      return css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `
    }
  }}
`

const LoaderDimmed = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexSystem.modal + 1};
`

const Spinner = styled.div`
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  z-index: ${zIndexSystem.modal + 2};
  width: 48px;
  height: 48px;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 5px solid #727272;
    animation: prixClipFix 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
`

export default Loader
