import loadable from '@loadable/component'
// import { chunkLoadErrorKey } from '@/utils/storage'

// 참고: https://www.codemzy.com/blog/fix-chunkloaderror-react
export const lazyComponent = function (componentImport, name) {
  return loadable(() => lazyRetry(componentImport, name))
}

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // const hasRefreshed = chunkLoadErrorKey.get()

    componentImport()
      .then((component) => {
        // chunkLoadErrorKey.set(false) // success so reset the refresh
        resolve(component)
      })
      .catch((error) => {
        // if (!hasRefreshed) {
        //   chunkLoadErrorKey.set(true)
        //   return window.location.reload() // refresh the page
        // }

        reject(error)
      })
  })
}
