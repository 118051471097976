import { useCallback, useState } from 'react'
import { getExcel } from '@/utils/request'
import dayjs from 'dayjs'
import { AxiosRequestConfig } from 'axios'
import MAlert from '@/utils/MAlert'
import { Utils } from '@/utils/Utils'
import MLoader from '@/utils/MLoader'

interface Params {
  fileNamePrefix?: string
  config?: AxiosRequestConfig
}

function useExcelDownload<T = UnknownMap>({ fileNamePrefix = 'Excel', config = { method: 'get' } }: Params = {}) {
  const [isLoading, setIsLoading] = useState(false)

  const getFileNameFromDisposition = useCallback((disposition: string) => {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\\.]+)(?:; ?|$)/i
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

    let fileName = ''
    if (utf8FilenameRegex.test(disposition)) {
      fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)?.[1] || '')
    } else {
      const filenameStart = disposition.toLowerCase().indexOf('filename=')

      if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart)
        const matches = asciiFilenameRegex.exec(partialDisposition)
        if (matches != null && matches[2]) fileName = matches[2]
      }
    }

    return fileName
  }, [])

  const onDownloadExcel = useCallback(
    async (url: string, params?: T) => {
      try {
        if (!url) return

        setIsLoading(true)
        MLoader.show()

        const resp =
          config?.method === 'post'
            ? await getExcel(url, { ...config, data: params })
            : await getExcel(`${url}${Utils.qsStringify(params || {})}`, config)
        const blobUrl = URL.createObjectURL(resp.data)

        setIsLoading(false)
        MLoader.hide()

        const filename =
          getFileNameFromDisposition(resp.headers?.['content-disposition'] || '') || `${fileNamePrefix}_${dayjs().format('YYYYMMDDhms')}.xlsx`

        const link = document.createElement('a')
        link.href = blobUrl
        link.download = filename
        link.click()
        URL.revokeObjectURL(blobUrl)
      } catch (e) {
        setIsLoading(false)
        MLoader.hide()

        MAlert.show('다운로드에 실패했습니다.')
      }
    },
    [fileNamePrefix],
  )

  return {
    isLoading,
    onDownloadExcel,
  }
}

export default useExcelDownload
