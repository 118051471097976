const getType = (value: unknown): string => {
  if (Array.isArray(value)) {
    const arrayTypes = new Set(value.map((item) => getType(item)))
    return arrayTypes.size > 0 ? `(${Array.from(arrayTypes).join(' | ')})[]` : 'any[]'
  }
  if (value === null) {
    return 'null'
  }
  if (typeof value === 'object' && value !== null) {
    return 'Record<string, any>'
  }
  return typeof value
}

const convertToNullable = (types: string[]): string => {
  if (types.includes('null')) {
    // null이 포함된 경우 Nullable로 변환
    const nonNullTypes = types.filter((type) => type !== 'null')
    return nonNullTypes.length > 0 ? `Nullable<${nonNullTypes.join(' | ')}>` : 'Nullable<unknown>'
  }
  return types.join(' | ')
}
const generateInterfaceFromArray = (objArray: Record<string, unknown>[], interfaceName = 'TempItem') => {
  const typeMap: Record<string, Set<string>> = {}

  objArray.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key]
      const valueType = getType(value)

      // Set에 타입 추가 (중복 제거)
      if (!typeMap[key]) {
        typeMap[key] = new Set()
      }
      typeMap[key].add(valueType)
    })
  })

  let result = `export interface ${interfaceName}Item {\n`

  Object.keys(typeMap).forEach((key) => {
    const types = Array.from(typeMap[key])
    const typeString = convertToNullable(types)
    result += `  ${key}: ${typeString};\n`
  })

  result += '}'
  // eslint-disable-next-line no-console
  console.log(result)
}

export const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .split('_')
    .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join('')
export const camelToSnake = (str: string) => str.replace(/([A-Z])/g, '_$1')

export const convertKeysToSnakeCase = <T extends Record<string, unknown>>(obj: object, notConvertList?: string[]): T => {
  const newObj = {} as T
  Object.keys(obj).forEach((key) => {
    let snakeCaseKey = camelToSnake(key) as keyof T
    if (!notConvertList?.includes(key)) {
      snakeCaseKey = (snakeCaseKey as string).toUpperCase() as keyof T
    }
    newObj[snakeCaseKey] = obj[key as keyof typeof obj] as T[keyof T]
  })
  return newObj
}
export const convertKeysToCamelCase = <T extends object>(objArray: object[], interfaceName?: string): T[] => {
  const res = objArray.map((obj) => {
    const newObj = {} as T
    Object.keys(obj).forEach((key) => {
      const camelCaseKey = snakeToCamel(key) as keyof T
      newObj[camelCaseKey] = obj[key as keyof typeof obj] as T[keyof T]
    })
    return newObj
  })
  if (interfaceName) {
    generateInterfaceFromArray(res as Record<string, unknown>[], interfaceName)
  }
  return res
}

export const convertKeysToGridRequest = (
  { params, ...rest }: ApiRequest<object> | ApiRequestDetail<object>,
  notConvertList?: string[],
): GridApiRequestBody => {
  return {
    params: JSON.stringify({ ...rest, params: convertKeysToSnakeCase(params, notConvertList) }),
  }
}
export const convertKeysToGridResponse = <T extends object, K extends object>(
  res: ApiResponse<T, K>,
  interfaceName?: string,
): ApiResponse<T, K> => {
  return {
    ...res,
    rtnData: convertKeysToCamelCase<T>(res?.rtnData || [], interfaceName),
  }
}
