import { MenuListRes } from '@/services/auth/menu.types'
import { isArray } from 'lodash-es'
import { MenuOnSessionWrap } from '@/utils/storage/MenuOnSessionWrap'

export class MenuStorage {
  static menu = {
    name: 'menus',
    get() {
      let list: MenuListRes = []

      try {
        const curMenu = sessionStorage.getItem(this.name) || ''
        if (curMenu) list = JSON.parse(curMenu)?.menus || []
      } catch (e) {
        // ERROR
        // console.dir(e)
      }

      return isArray(list) ? list : []
    },
    set(list: MenuListRes) {
      sessionStorage.setItem(
        this.name,
        // FIXME moms 1.0 때문에 menus로 한번더 감싸기
        JSON.stringify({
          menus: list.map((menu) => ({
            ...menu,
            MENU_ITEM: menu.MENU_ITEM.map((child) => ({
              ...child,
              // FIXME: 1.0에서 아래 필드들이 필요함. (상단 active tab을 위해 필요)
              MENU_ACTIVE: false,
              MENU_OPEN: false,
              MENU_TAB: 0,
            })),
          })),
        }),
      )
    },
    clear() {
      sessionStorage.removeItem(this.name)
    },
  }

  static menuOnSession = new MenuOnSessionWrap('moms_menu_on_session')

  static menuOnSessionLegacy = new MenuOnSessionWrap('moms_legacy_menu_on_session')
}
