import { ROUTES_WITH_ELEMENTS } from '@/utils/routes/routesWithElements'

// e.g. PATHS['auth'], PATHS['auth.reset-otp']
export const PATHS: PathMapType = Object.entries(ROUTES_WITH_ELEMENTS).reduce((parentAcc, parent) => {
  const parentKey = parent[0] as keyof typeof ROUTES_WITH_ELEMENTS
  const childMap = parent[1]

  // 실제 Path는 kebabCase 로 처리 (resetOtp -> reset-otp)
  return {
    ...parentAcc,
    [parentKey]: `/${parentKey}`,
    ...Object.entries(childMap).reduce(
      (childAcc, [childPath]) => ({
        ...childAcc,
        [`${parentKey}.${childPath}`]: `/${parentKey}/${childPath}`,
      }),
      {} as PathMapType,
    ),
  }
}, {} as PathMapType)

type PathKeys = NestedKeysWithParent<typeof ROUTES_WITH_ELEMENTS>
type PathMapType = Record<PathKeys, string>
