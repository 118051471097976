import React from 'react'
import Loader from '@/components/content/Loader'
import { createRoot, Root } from 'react-dom/client'

const LOADER_ID = 'moms-loader'

class MLoaderAction {
  root: Root | null = null

  constructor() {
    const $wrapper = document.getElementById(LOADER_ID)
    if (!$wrapper) return

    this.root = createRoot($wrapper)
  }

  hide = () => {
    if (this.root) {
      // 500ms 로딩 제거 지연 (너무 빠른 api 응답일 경우 사용자가 인지하기 어려움)
      setTimeout(() => this.root?.render(null), 500)
    }
  }

  show = () => {
    if (this.root) {
      this.root.render(<Loader dimmed position="fixed-center" />)
    }
  }
}

const MLoader = new MLoaderAction()
export default MLoader
