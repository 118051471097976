import { useQuery } from '@tanstack/react-query'
import { AuthStorage } from '@/utils/storage/AuthStorage'
import { HistoryDownloadLogReqParams, HistoryUserLogReqParams, InterfaceHistoryParams } from '@/services/history/history.types'
import { getHistoryDownloadLog, getHistoryUserLog, getInterfaceHistory } from '@/services/history/apis'

export const PAGE_SIZE_ON_HISTORY = 10000

const GET_HISTORY_USER_LOG = ['HS01', 'GET_HISTORY_USER_LOG']
const GET_HISTORY_DOWNLOAD_LOG = ['HS03', 'GET_HISTORY_DOWNLOAD_LOG']
const GET_INTERFACE_HISTORY = ['RP06', 'GET_INTERFACE_HISTORY']

export const useGetHistoryUserLog = (params: HistoryUserLogReqParams) => {
  const reqParams = {
    MENU_CD: 'HS01_HEAD',
    USR_ID: AuthStorage.user.get().email || '',
    params,
    type: 'keyboard',
    page: 1,
  }

  const { data, ...rest } = useQuery({
    queryKey: GET_HISTORY_USER_LOG,
    queryFn: () => getHistoryUserLog(reqParams),
    enabled: false,
  })

  return {
    ...rest,
    data: data?.rtnData || [],
  }
}

export const useGetHistoryDownloadLog = (params: HistoryDownloadLogReqParams) => {
  const reqParams = {
    MENU_CD: 'HS03_HEAD',
    USR_ID: AuthStorage.user.get().email || '',
    params,
    type: 'keyboard',
    page: 1,
  }

  const { data, ...rest } = useQuery({
    queryKey: GET_HISTORY_DOWNLOAD_LOG,
    queryFn: () => getHistoryDownloadLog(reqParams),
    select: ({ rtnData }) => ({
      list: rtnData || [],
      totalPages: rtnData?.length ? 1 : 0,
      totalElements: rtnData?.length ?? 0,

      // TODO: Pagination이 필요한 경우 아래와 같이 처리
      // originList: rtnData || [],
      // list: Utils.chunkArray(rtnData || [], PAGE_SIZE_ON_HISTORY),
      // totalPages: rtnData ? Math.ceil(rtnData.length / PAGE_SIZE_ON_HISTORY) : 0,
      // totalElements: rtnData?.length ?? 0,
    }),
    enabled: false,
  })

  return {
    ...rest,
    data,
  }
}

export const useGetInterfaceHistory = (params: InterfaceHistoryParams) => {
  const reqParams = {
    MENU_CD: 'RP06_HEAD',
    USR_ID: AuthStorage.user.get().email || '',
    params,
    type: 'click',
    page: 1,
  }

  const { data, ...rest } = useQuery({
    queryKey: GET_INTERFACE_HISTORY,
    queryFn: () => getInterfaceHistory(reqParams),
    select: ({ rtnData }) => ({
      list: rtnData || [],
      totalPages: rtnData?.length ? 1 : 0,
      totalElements: rtnData?.length ?? 0,
    }),
    enabled: false,
  })

  return {
    ...rest,
    data,
  }
}
