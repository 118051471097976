import { useInfiniteQuery } from '@tanstack/react-query'
import { getDepartmentList, getShipperList } from '@/services/shipper/apis'

const PATH = ['common', 'shipper']
export const GET_SHIPPER_LIST = [...PATH, 'GET_SHIPPER_LIST']
export const GET_DEPARTMENT_LIST = [...PATH, 'GET_DEPARTMENT_LIST']

export const useGetShipperList = (shipperName = '') => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: GET_SHIPPER_LIST,
    queryFn: ({ pageParam }) => getShipperList(shipperName, pageParam.page),
    initialPageParam: { page: 1 },
    getNextPageParam: (lastPage) => {
      return {
        page: !lastPage?.params?.page || lastPage.params.page <= 1 ? 1 : lastPage.params.page + 1,
      }
    },
    enabled: false,
  })

  return {
    ...rest,
    data: {
      list: data?.pages.flatMap((page) => page.rtnData || []) ?? [],
    },
  }
}

export const useGetDepartmentList = (shipperCode = '') => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: GET_DEPARTMENT_LIST,
    queryFn: ({ pageParam }) => getDepartmentList(shipperCode, pageParam.page),
    initialPageParam: { page: 1 },
    getNextPageParam: (lastPage) => {
      return {
        page: !lastPage?.params?.page || lastPage.params.page <= 1 ? 1 : lastPage.params.page + 1,
      }
    },
    enabled: false,
  })

  return {
    ...rest,
    data: {
      list: data?.pages.flatMap((page) => page.rtnData || []) ?? [],
    },
  }
}
