import * as React from 'react'
import styled from 'styled-components'
import Text from '@/components/content/Text'
import { VStack } from '@/components/content/Stack'

interface Props {
  className?: string
  label?: string
  children?: React.ReactNode
  block?: boolean
}

function RadioGroup({ className, label, children, block = false }: Props) {
  return (
    <RadioGroupWrap gap={12} className={className} block={block}>
      <Text kind="Body_14_bold">{label}</Text>
      {children}
    </RadioGroupWrap>
  )
}

const RadioGroupWrap = styled(VStack)``

export default RadioGroup
