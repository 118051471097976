import { PATHS } from '@/utils/routes/paths'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useRedirectAuth = () => {
  const { pathname } = useLocation()

  // 임베딩 페이지에서 로그인 페이지로 이동된 경우, 상위 페이지를 로그인 페이지로 이동
  useEffect(() => {
    if (window.top !== window.self) {
      if (pathname === PATHS['auth.login']) {
        window.parent.postMessage({ event: 'VISIT_LOGIN_PAGE' }, '*')
      }
    }
  }, [pathname])
}
