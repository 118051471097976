export const COLUMN_WIDTH = {
  time: 120,
  date: 160,
  dateTime: 180,
  yna: 80,
  number: 120,
  status: 140,
  tinyText: 200,
  text: 400,
  author: 150,
}
