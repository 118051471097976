import React, { useCallback, useMemo } from 'react'
import Modal from '@/components/content/Modal'
import Input from '@/components/content/Input'
import FormTable from '@/components/content/FormTable'
import { useAddDeliveryArea } from '@/services/deliveryAreas/useDeliveryAreasQueries'
import { useForm } from 'react-hook-form'
import { AddDeliveryAreaReq } from '@/services/deliveryAreas/types'
import { chunk, toNumber } from 'lodash-es'
import RadioBool from '@/components/content/RadioBool'
import SelectBoxCity from '@/pages/master/deliveryAreas/components/SelectBoxCity'
import MToast from '@/utils/MToast'
import MAlert from '@/utils/MAlert'
import { Utils } from '@/utils/Utils'
import { DeliveryAreasUtils } from '@/pages/master/deliveryAreas/components/DeliveryAreasUtils'
import { DA_CITY_WITH_NULLABLE_DISTRICT } from '@/pages/master/deliveryAreas/components/consts'

interface Props {
  open: boolean
  onClose: () => void
  onRefresh: () => void
}

function DeliveryAreasAddModal({ open, onClose, onRefresh }: Props) {
  const addDeliveryArea = useAddDeliveryArea()
  const { watch, setValue, getValues } = useForm<AddDeliveryAreaReq>({
    defaultValues: {
      zipcode: '',
      city: undefined,
      district: '',
      town: '',
      deliveryLeadDay: 0,
      remoteArea: false,
    },
  })

  const values = watch()
  const isRequiredDistrict = useMemo(() => values.city !== DA_CITY_WITH_NULLABLE_DISTRICT, [values.city])

  const handleSubmit = useCallback(() => {
    const formData = getValues()
    const isValid = DeliveryAreasUtils.validateFields(formData)
    if (!isValid) return

    addDeliveryArea
      .mutateAsync({
        ...formData,
        district: formData.district || null,
      })
      .then(() => {
        MToast.show('등록에 성공했습니다.')

        setTimeout(() => {
          onRefresh()
          onClose()
        }, 100)
      })
      .catch((e) => {
        MAlert.show(Utils.getApiErrMessage(e, '추가에 실패했습니다.'))
      })
  }, [])

  return !open ? null : (
    <Modal open size="md" title="우편번호권역 추가" buttons={[{ kind: 'primary_light', content: '추가', onClick: handleSubmit }]} onClose={onClose}>
      <FormTable
        list={chunk(
          [
            {
              label: '우편번호',
              required: true,
              value: <Input value={values.zipcode} onChange={(v) => setValue('zipcode', v)} regex={/[^0-9]/g} />,
            },
            {
              label: '시구분',
              required: true,
              value: <SelectBoxCity value={values.city} onChange={(v) => setValue('city', v)} />,
            },
            {
              label: '구구분',
              required: isRequiredDistrict,
              value: <Input value={values.district || ''} onChange={(v) => setValue('district', v)} />,
            },
            {
              label: '읍면동',
              required: true,
              value: <Input value={values.town} onChange={(v) => setValue('town', v)} />,
            },
            {
              label: '배송리드타임',
              required: true,
              value: <Input type="number" value={values.deliveryLeadDay} onChange={(v) => setValue('deliveryLeadDay', toNumber(v))} />,
            },
            {
              label: '도서산간여부',
              required: true,
              value: <RadioBool value={values.remoteArea} onChange={(v) => setValue('remoteArea', v)} />,
            },
          ],
          2,
        )}
      />
    </Modal>
  )
}

export default DeliveryAreasAddModal
