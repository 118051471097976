import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { colorSystem } from '@/styles/theme'
import { VStack } from '@/components/content/Stack'
import Text from '@/components/content/Text'
import Button from '@/components/content/Button'

export interface FileInputProps {
  className?: string
  trigger?: React.ReactNode
  inputClassName?: string
  inputId?: string
  value?: string // filename
  onChange?: (e: File) => void
  accept?: string // ".xlsx, .xls, .csv"
}

function FileInput({ inputId, trigger, inputClassName, className, value, onChange, accept }: FileInputProps) {
  const ref = useRef<HTMLInputElement>(null)
  const Trigger = useCallback(
    () =>
      trigger
        ? React.cloneElement(trigger as React.ReactElement, {
            onClick: () => ref.current?.click(),
          })
        : null,
    [trigger],
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return

      const file = e.currentTarget?.files?.[0]
      if (file) {
        onChange(file)
        setTimeout(() => {
          if (ref.current) ref.current.value = ''
        }, 0)
      }
    },
    [onChange],
  )

  return (
    <>
      {trigger ? (
        <>
          <Trigger />
        </>
      ) : (
        <FileInputWrapper className={className} align="center" p={15} gap={6}>
          {value ? (
            <Text kind="Body_14_regular" style={{ height: '36px' }}>
              {value}
            </Text>
          ) : (
            <Text kind="Body_12_regular" color={colorSystem.text.common.tertiary}>
              인쇄할 상품코드 정보를
              <br />
              업로드 해주세요.
            </Text>
          )}

          <Button size="sm" kind="secondary" content="파일 선택" onClick={() => ref.current?.click()} />
        </FileInputWrapper>
      )}
      <StyledInput hidden id={inputId} className={inputClassName} ref={ref} type="file" onChange={handleChange} accept={accept} />
    </>
  )
}

const FileInputWrapper = styled(VStack)`
  width: 100%;
  padding: 15px;
  align-items: center;
  border-radius: 6px;
  border: 1px dashed ${colorSystem.border.divider};
`

const StyledInput = styled.input`
  display: none;

  &::file-selector-button {
    display: none;
  }
`

export default FileInput
