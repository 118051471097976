import * as React from 'react'
import { ReactElement, useMemo } from 'react'
import styled from 'styled-components'
import Button, { ButtonProps } from '@/components/content/Button'
import { HStack, VStack } from '@/components/content/Stack'
import { borderRadius, colorSystem } from '@/styles/theme'
import FieldList, { FieldListProps } from '@/components/content/FieldList'
import { isArray, isFinite, isNil } from 'lodash-es'

interface Props extends FieldListProps {
  className?: string
  isLoading?: boolean
  buttons?: ButtonProps[]
  onSave?: () => void
  onSearch?: () => void
  onReset?: () => void
}

const isEmpty = (value: string) => {
  if (value === 'Invalid Date' || value === 'Infinity') {
    return true
  }

  if (typeof value === 'number' && !isFinite(value)) {
    return true
  }

  return !value
}

function FilterList({ className, isLoading, onSave, onSearch, onReset, list, buttons }: Props) {
  const isRequiredNotFilled = useMemo(() => {
    return list.some((item) =>
      item.some(({ required, value }) => {
        const comp = value as ReactElement
        if (!required || !comp) {
          return false
        }

        const triggerComp = comp.props?.trigger as ReactElement | undefined
        if (triggerComp) {
          return isArray(triggerComp.props.value) ? triggerComp.props.value.some(isEmpty) : isEmpty(triggerComp.props.value)
        }

        return isArray(comp.props.value) ? comp.props.value.some(isEmpty) : isEmpty(comp.props.value)
      }),
    )
  }, [list])

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <FilterListWrap className={className}>
        <FieldList list={list} />
        <ButtonWrap gap={8} p="12px 20px" justify="flex-end">
          {buttons?.length && buttons?.map((btn, idx) => <Button key={`filter-list-${btn.content}-${idx}`} {...btn} />)}
          {onSave && (
            <Button kind="primary_dark" type="button" disabled={isLoading} onClick={onSave}>
              저장
            </Button>
          )}
          {onReset && (
            <Button kind="secondary" type="button" disabled={isLoading} onClick={onReset}>
              초기화
            </Button>
          )}
          {onSearch && (
            <Button kind="primary_light" type="submit" disabled={isLoading || isRequiredNotFilled} onClick={onSearch}>
              검색
            </Button>
          )}
        </ButtonWrap>
      </FilterListWrap>
    </form>
  )
}

const FilterListWrap = styled(VStack)`
  background: ${colorSystem.bg.filter};
  padding-bottom: 16px;
  ${borderRadius.md}
`

const ButtonWrap = styled(HStack)``

export default FilterList
