import * as React from 'react'
import Modal from '@/components/content/Modal'
import { useCallback, useState } from 'react'
import Radio from '@/components/content/Radio'
import RadioGroup from '@/components/content/RadioGroup'
import { useCompleteExport, useGetExportCombo } from '@/services/excel/useExcelQuries'
import { HStack, VStack } from '@/components/content/Stack'
import Input from '@/components/content/Input'
import styled from 'styled-components'
import Text from '@/components/content/Text'
import { colorSystem } from '@/styles/theme'
import MAlert from '@/utils/MAlert'
import { Utils } from '@/utils/Utils'

export interface GridExportModalProps {
  onClose: () => void
  onExportData?: () => Promise<void> | void
}

function GridExportModal({ onClose, onExportData }: GridExportModalProps) {
  const [selectCode, setSelectCode] = useState('')
  const [remark, setRemark] = useState('')
  const [errorText, setErrorText] = useState('')

  const { data: combo } = useGetExportCombo()
  const completeExport = useCompleteExport()

  const onConfirm = useCallback(async () => {
    try {
      if (!selectCode) return setErrorText('사유코드를 선택해주세요.')
      if (remark.length < 5) return setErrorText('상세사유를 확인해주세요.')

      setErrorText('')

      if (onExportData) {
        await onExportData()
      }

      const remarkParams = `${selectCode}_${remark}`
      completeExport.mutateAsync(remarkParams).then(onClose)
    } catch (e) {
      const defaultMessage = (e as Error).message ?? '엑셀 다운로드에 실패하였습니다. 다시 시도해주세요.'
      MAlert.show(Utils.getApiErrMessage(e, defaultMessage))
    }
  }, [selectCode, remark])

  return (
    <Modal
      open
      closable
      title="다운로드 사유"
      onClose={onClose}
      buttons={[
        {
          content: '확인',
          kind: 'primary_light',
          onClick: onConfirm,
        },
        {
          content: '취소',
          kind: 'primary_light',
          onClick: onClose,
        },
      ]}
    >
      {errorText && (
        <ErrorText kind="Headline_16_bold" color={colorSystem.text.common.error}>
          {errorText}
        </ErrorText>
      )}
      <ModalContents>
        {combo && (
          <RadioGroup block label="사유코드">
            {combo?.DOWNLOAD_CD?.COMBO_OPT_CD?.map((code) => (
              <Radio key={`grid-export-modal-${code}`} value={code} label={code} checked={selectCode === code} onChange={setSelectCode} />
            ))}
          </RadioGroup>
        )}
        <VStack block>
          <Input value={remark} label="상세사유" onChange={setRemark} helpText={remark.length < 5 ? '5글자 이상 입력해주세요.' : ''} />
        </VStack>
      </ModalContents>
    </Modal>
  )
}

const ErrorText = styled(Text)`
  text-align: center;
  display: block;
  margin-bottom: 16px;
`

const ModalContents = styled(HStack)``

export default GridExportModal
