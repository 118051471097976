import * as React from 'react'
import styled from 'styled-components'
import { VStack } from '@/components/content/Stack'

interface Props {
  children?: React.ReactNode
}

function PageLayout({ children }: Props) {
  return <Wrapper gap={20}>{children}</Wrapper>
}

const Wrapper = styled(VStack)`
  height: 100%;
`

export default PageLayout
