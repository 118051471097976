import React from 'react'
import { AlertModalList, AlertModalProps } from '@/components/content/Alert'
import { Utils } from '@/utils/Utils'
import { createRoot, Root } from 'react-dom/client'

const ALERT_ID = 'moms-alert'

class MAlertAction {
  list: Omit<AlertModalProps, 'open'>[] = []

  root: Root | null = null

  constructor() {
    const $wrapper = document.getElementById(ALERT_ID)
    if (!$wrapper) return

    this.root = createRoot($wrapper)
  }

  destroy = (id: string) => {
    const deletedIdx = this.list.findIndex((alert) => alert.id === id)
    this.list.splice(deletedIdx, 1)

    if (this.root && this.list.length) {
      this.root.render(<AlertModalList list={this.list} destroy={this.destroy} />)
    }
  }

  show = (content: React.ReactNode, props?: Pick<AlertModalProps, 'onClose' | 'closable'>) => {
    if (!this.list.some((alert) => alert.content === content)) {
      this.list.push({ ...props, content, id: Utils.uuid() })
    }

    if (this.root) {
      this.root.render(<AlertModalList list={this.list} destroy={this.destroy} />)
    }
  }
}

const MAlert = new MAlertAction()
export default MAlert
