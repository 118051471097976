import React from 'react'
import SelectBox from '@/components/content/SelectBox'
import { useGetCitiesOnDeliveryAreas } from '@/services/deliveryAreas/useDeliveryAreasQueries'

interface Props {
  value?: string
  onChange: (v: string) => void
}

function SelectBoxCity({ value, onChange }: Props) {
  const { options: cityOptions = [] } = useGetCitiesOnDeliveryAreas()
  return <SelectBox block placeholder="'시구분'을 선택해주세요" value={value} options={cityOptions} onChange={onChange} />
}

export default SelectBoxCity
